import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';

const SuggestedPrompts = ({ prompts, onSelectPrompt, title = "Suggested Prompts" }) => {
  if (!prompts || prompts.length === 0) return null;

  return (
    <Box
      mt={3}
      mb={3}
      style={{
        padding: "16px",
        borderRadius: "8px"
      }}
    >
      <Typography
        variant="h6"
        style={{
          color: "#545567",
          fontWeight: "600",
          fontSize: "16px",
          textAlign: "center",
          marginBottom: "16px",
        }}
      >
        {title}
      </Typography>
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "12px",
        }}
      >
        {prompts.map((prompt, idx) => (
          <Tooltip key={idx} title={prompt} placement="top" arrow>
            <button
              onClick={() => onSelectPrompt(prompt)}
              style={{
                backgroundColor: "#e0e0e0",
                border: "none",
                borderRadius: "8px",
                padding: "6px 16px",
                fontSize: "14px",
                cursor: "pointer",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s ease",
                maxWidth: "300px",
                flex: "1 1 calc(33.33% - 12px)",
                whiteSpace: "normal",
                textAlign: "center",
                lineHeight: "1.4",
                fontWeight: "500",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#d6d6d6";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#e0e0e0";
              }}
            >
              {prompt}
            </button>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
};

export default SuggestedPrompts;
