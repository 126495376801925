// React and core functionality
import React from "react";

// Material-UI components
import {
  Grid,
  Box,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Typography,
  Switch
} from "@mui/material";

// Styling utilities
import { styled } from "@mui/material/styles";

// Custom components
import AdvancedOptions from "../reusableComponent/AdvancedOptions";

const Toolbar = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
}));

const AiChatSelection = ({ input, setInput }) => {
  
  const handleChangeTemporaryChat = (event) => {
    const isTemp = event.target.checked;
    setInput((prevInput) => ({
      ...prevInput,
      isTemporaryChat: isTemp
    }));
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {/* AI-Chat Title and Toolbar */}
        <Grid item xs={12}>
          <div className="select-box" data-testid="setting-component">
            <Toolbar>
              <h3 className="titlename">AI-Chat</h3>
              <Tooltip
                title="When enabled, your chats won't be saved in Scribe. They are temporarily processed by the AI only to generate responses, following OpenAI's terms and conditions."
              >
                <FormGroup sx={{ marginTop: '15px', display: "flex", marginLeft: 'auto' }}>
                  <FormControlLabel sx={{ marginRight: '0px', marginTop: '3px' }} control={<Switch
                    size="small"
                    checked={input.isTemporaryChat}
                    onChange={handleChangeTemporaryChat}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />} label={<Typography sx={{ fontSize: "small" }}>
                    Temporary Chat
                  </Typography>} />
                </FormGroup>
              </Tooltip>
            </Toolbar>
          </div>
        </Grid>

        {/* Advanced Options */}
        <Grid item xs={12}>
          <AdvancedOptions input={input} setInput={setInput} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AiChatSelection;