import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  LinearProgress,
  Paper,
} from "@mui/material";
import {
  Send as SendIcon,
  Mic as MicIcon,
  AttachFile as AttachFileIcon,
  FileUpload as FileUploadIcon,
  AddToDrive as AddToDriveIcon,
} from "@mui/icons-material";
import toast from "react-hot-toast";
import googleApi from "../../server/googleApi";
import AiChatTools from "./aiChatTools";

const languageMap = {
  "English (British English)": "en-GB",
  "English (American)": "en-US",
  "Mandarin Chinese": "zh-CN",
  "Spanish": "es-ES",
  "Hindi-Urdu": "hi-IN",
  "Arabic": "ar-SA",
  "Bengali": "bn-BD",
  "Portuguese (European)": "pt-PT",
  "Russian": "ru-RU",
  "Indonesian": "id-ID",
  "French (Standard)": "fr-FR",
  "French (Quebec)": "fr-CA",
};

const AiPromptBar = ({ onSend, language = "English (British English)", mode = "light", variant = "aiChat" }) => {
  const [message, setMessage] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [selectedTool, setSelectedTool] = useState(null);
  const [placeholder, setPlaceholder] = useState("Type or speak your message or prompt...");
  const [anchorEl, setAnchorEl] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [oAuthInProgress, setOAuthInProgress] = useState(false);

  const isAiChat = variant === "aiChat";

  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      const speechRecognition = new window.webkitSpeechRecognition();
      speechRecognition.continuous = false;
      speechRecognition.interimResults = true;

      const languageCode = languageMap[language] || "en-GB";
      speechRecognition.lang = languageCode;

      speechRecognition.onresult = (event) => {
        let finalText = "";
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalText += transcript;
          }
        }
        if (finalText) setMessage((prev) => prev + finalText);
      };

      speechRecognition.onerror = (event) => {
        toast.error(event.error);
      };

      speechRecognition.onend = () => {
        setIsListening(false);
      };

      setRecognition(speechRecognition);
    } else {
      toast.error("Web Speech API is not supported in this browser.");
    }
  }, []);

  useEffect(() => {
    if (selectedTool) {
      const placeholders = {
        googleSearch: "Type your search query...",
        crawlAndExtractTextFromWebsite: "Enter the website URL...",
        generateImageResponse: "Describe the image you want to generate...",
        generateWeatherForecastMessage: "Enter the location for weather forecast...",
      };
      setPlaceholder(placeholders[selectedTool.value] || "Type or speak your message or prompt...");
    }
  }, [selectedTool]);

  useEffect(() => {
    const GOOGLE_PICKER_SCRIPT_ID = "google-picker-script";
    const GOOGLE_IDENTITY_SCRIPT_ID = "google-identity-script";

    const loadScript = (id, src, onLoad, onError) => {
      if (!document.getElementById(id)) {
        const script = document.createElement("script");
        script.id = id;
        script.src = src;
        script.onload = onLoad;
        script.onerror = onError;
        document.body.appendChild(script);
      }
    };

    const handlePickerLoad = () => {
      gapi.load("picker", () => { });
    };

    const handleIdentityLoad = () => { };

    const handleScriptError = (id) => {
      toast.error(`Error loading ${id} script.`);
    };

    // Load Google Picker API script
    loadScript(
      GOOGLE_PICKER_SCRIPT_ID,
      "https://apis.google.com/js/api.js",
      handlePickerLoad,
      () => handleScriptError(GOOGLE_PICKER_SCRIPT_ID)
    );

    // Load Google Identity Services script
    loadScript(
      GOOGLE_IDENTITY_SCRIPT_ID,
      "https://accounts.google.com/gsi/client",
      handleIdentityLoad,
      () => handleScriptError(GOOGLE_IDENTITY_SCRIPT_ID)
    );

    if (!oAuthInProgress) {
      setOAuthInProgress(true); // Mark OAuth as in progress
      checkForOAuthCode();
    }

    // Cleanup scripts on unmount
    return () => {
      const pickerScript = document.getElementById(GOOGLE_PICKER_SCRIPT_ID);
      const identityScript = document.getElementById(GOOGLE_IDENTITY_SCRIPT_ID);

      if (pickerScript) {
        pickerScript.parentNode?.removeChild(pickerScript);
      }
      if (identityScript) {
        identityScript.parentNode?.removeChild(identityScript);
      }
    };
  }, [oAuthInProgress]);

  const checkForOAuthCode = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      let cleanUrl;
      try {
        cleanUrl = cleanUrlParams(window.location.href);
        const tokens = await googleApi.handleGoogleAuthCallback(code, cleanUrl);

        if (tokens?.access_token) {
          setAccessToken(tokens.access_token);
          setExpiryDate(tokens.expiry_date || null);

          // Directly show the Picker after obtaining tokens
          showPicker(tokens.access_token);
        }

        window.history.replaceState({}, document.title, cleanUrl);
      } catch (error) {
        toast.error("Error during OAuth callback");
      } finally {
        setOAuthInProgress(false); // Mark OAuth process as complete
        cleanUrl = cleanUrl || cleanUrlParams(window.location.href);
        window.history.replaceState({}, document.title, cleanUrl);
      }
    } else {
      setOAuthInProgress(false); // No OAuth code found
    }
  };

  const isTokenExpired = () => expiryDate && Date.now() >= expiryDate;

  const getToolChoice = (selectedTool) => {
    return selectedTool
      ? { type: "function", function: { name: selectedTool.function.name } }
      : "auto";
  };

  const handleVoiceInput = () => {
    if (recognition) {
      if (isListening) {
        recognition.stop();
      } else {
        setMessage("");
        recognition.start();
      }
      setIsListening((prev) => !prev);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleSend = () => {
    if (message.trim()) {
      const toolChoice = getToolChoice(selectedTool);
      onSend({ message, tool_choice: toolChoice });
      setMessage("");
      setSelectedTool(null);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["csv", "xls", "xlsx", "doc", "docx", "txt"];
      const fileType = file.name.split(".").pop().toLowerCase();

      if (!allowedTypes.includes(fileType)) {
        toast.error(`Unsupported file type: .${fileType}`);
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size exceeds 5MB.");
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          setFileLoading(true);
          const fileContent = e.target.result;
          const toolChoice = getToolChoice(selectedTool);
          onSend({ message: fileContent, tool_choice: toolChoice });
        } catch (error) {
          toast.error("Error reading file");
        } finally {
          setFileLoading(false);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleGoogleDriveClick = async () => {
    try {
      setFileLoading(true);
      toast.loading("Connecting to Google Drive...");

      if (!accessToken || isTokenExpired()) {
        // Get the current URL
        let redirectUri = window.location.href;

        // Check if fullscreenMode is already in the URL
        const currentUrlObj = new URL(redirectUri);
        if (variant === "aiChat" && !currentUrlObj.searchParams.has("fullscreenMode")) {
          currentUrlObj.searchParams.append("fullscreenMode", "true");
          redirectUri = currentUrlObj.toString();
        }

        // Fetch the Google authentication URL with the updated redirectUri
        const authUrl = await googleApi.fetchGoogleAuthUrl(redirectUri);
        window.location.href = authUrl;
      } else {
        showPicker(accessToken);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Failed to connect to Google Drive");
    } finally {
      toast.dismiss();
      setFileLoading(false);
    }
  };

  const showPicker = (token) => {
    if (!token) {
      toast.error("Access token is missing!"); // Handle missing token
      return;
    }

    const picker = new google.picker.PickerBuilder()
      .addView(google.picker.ViewId.DOCS)
      .setOAuthToken(token)
      .setCallback((data) => pickerCallback(data, token))
      .build();
    picker.setVisible(true);
  };

  const pickerCallback = async (data, accessToken) => {
    if (data.action === google.picker.Action.PICKED) {
      const doc = data.docs[0];
      const fileId = doc.id;

      try {
        setFileLoading(true); // Set loading state to true

        // Fetch file content as Blob using the provided accessToken
        const fileBlob = await googleApi.getFileContent(fileId, accessToken);

        // Check the content type of the fileBlob
        const contentType = fileBlob.type;

        let fileContent;
        if (contentType.includes("text/csv")) {
          // Convert CSV Blob to text and then to markdown
          const csvText = await blobToString(fileBlob);
          fileContent = convertCsvToMarkdown(csvText);
        } else {
          // Handle as plain text for other types
          fileContent = await blobToString(fileBlob);
        }

        // Ensure file content is fully read before submitting
        if (fileContent) {
          const toolChoice = getToolChoice(selectedTool);
          onSend({ message: fileContent, tool_choice: toolChoice });
        } else {
          toast.error('The file content is empty or could not be read.');
        }
      } catch (error) {
        toast.error('Failed to retrieve file content from Google Drive');
        console.error('Google Drive file error:', error);
      } finally {
        setFileLoading(false); // Reset loading state
      }
    } else if (data.action === google.picker.Action.CANCEL) {
      toast.error('File selection canceled');
    }
  };

  // Helper function to convert Blob to text
  const blobToString = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  };

  // Convert CSV content to markdown table format with auto-detected separator
  const convertCsvToMarkdown = (csv) => {
    const possibleSeparators = [',', ';', '\t', '|'];

    // Function to detect the separator based on the first line
    const detectSeparator = (line) => {
      const separatorCounts = possibleSeparators.map(sep => ({
        sep,
        count: line.split(sep).length
      }));
      // Choose the separator that results in the most columns
      separatorCounts.sort((a, b) => b.count - a.count);
      return separatorCounts[0].sep;
    };

    const rows = csv.trim().split("\n");
    const separator = detectSeparator(rows[0]);
    const headers = rows[0].split(separator);
    const mdTable = [];

    // Markdown table header
    mdTable.push(`| ${headers.join(" | ")} |`);
    mdTable.push(`| ${headers.map(() => "---").join(" | ")} |`);

    // Add rows in markdown table format
    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(separator);
      mdTable.push(`| ${row.join(" | ")} |`);
    }
    return mdTable.join("\n");
  };

  const cleanUrlParams = (url, paramsToRemove = ['code', 'scope']) => {
    const urlObj = new URL(url);
    paramsToRemove.forEach(param => urlObj.searchParams.delete(param));
    return urlObj.toString();
  };

  const commonStyles = {
    container: {
      padding: "16px",
      backgroundColor: mode === "dark" ? "#4a4b5d" : "#fff",
      color: mode === "dark" ? "#fff" : "#000",
      borderRadius: "8px",
    },
    textField: {
      backgroundColor: mode === "dark" ? "#3f4051" : "#f5f5f5",
      borderRadius: "8px",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "none",
        },
      },
      "& .MuiInputBase-input": {
        color: mode === "dark" ? "#fff" : "#000",
        "&::placeholder": {
          color: mode === "dark" ? "#aaa" : "#888"
        },
      },
    },
    menu: {
      "& .MuiPaper-root": {
        backgroundColor: mode === "dark" ? "#333" : "#fff",
        color: mode === "dark" ? "#fff" : "#000",
      },
      "& .MuiMenuItem-root": {
        "&:hover": {
          backgroundColor: mode === "dark" ? "#444" : "#f0f0f0",
        },
      },
    },
    sendButton: {
      backgroundColor: "#24ADE4",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#1c8dbf",
      },
    },
  };

  return (
    <Paper elevation={isAiChat ? 3 : 1} sx={commonStyles.container}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <TextField
            fullWidth
            multiline
            minRows={1}
            maxRows={isAiChat ? 4 : 8}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            sx={commonStyles.textField}
          />
          <IconButton
            onClick={handleVoiceInput}
            aria-label="Toggle voice input"
            sx={{
              backgroundColor: isListening ? "#d32f2f" : "#f5f5f5",
              color: isListening ? "#fff" : "#000",
              borderRadius: "50%",
            }}
          >
            <MicIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <input
              type="file"
              accept=".csv, .xls, .xlsx, .doc, .docx, .txt"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-upload"
            />
            <IconButton onClick={handleMenuOpen}>
              <AttachFileIcon />
            </IconButton>
            <Menu
              id="file-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              MenuProps={{
                anchorOrigin: {
                  vertical: isAiChat ? "top" : "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: isAiChat ? "bottom" : "top",
                  horizontal: "center",
                },
              }}
              sx={commonStyles.menu}
            >
              <MenuItem onClick={() => document.getElementById("file-upload").click()}>
                <FileUploadIcon sx={{ marginRight: 1 }} />
                Upload from Computer
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleGoogleDriveClick}>
                <AddToDriveIcon sx={{ marginRight: 1 }} />
                Connect to Google Drive
              </MenuItem>
            </Menu>
            <AiChatTools
              selectedTool={selectedTool}
              onSelectTool={setSelectedTool}
              mode={isAiChat ? "dark" : "light"}
            />
          </Box>
          <IconButton
            onClick={handleSend}
            disabled={!message.trim()}
            sx={commonStyles.sendButton}
          >
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
      {fileLoading && <LinearProgress />}
    </Paper>
  );
};

export default AiPromptBar;
