// React and React Router
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Material UI Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

// Editor-related Imports
import Texteditor from "../template/textEditor.js";
import draftToHtml from 'draftjs-to-html';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Styling and Custom Components
import PushToCueSelection from "./PushToCMSSelection.js";

// API and Local Files
import cmsApi from "../../server/cmsApi.js";
import completionsApi from "../../server/completionsApi.js";
import { generateConfig } from "../aiChat/aiChatConfigHelper.js";
import { userProfileContext } from "../../lib/UserProvider.js";
import { cleanContent } from "../reusableComponent/helpers.js";

// Local Definitions from 'definitions' file
import {
  articleTypes,
  languages,              // Predefined set of language options
  lengths,                // Predefined set of length units
  temperatures,           // Predefined set of temperature units
  tones,                  // Predefined set of tones
  teams,                  // Predefined set of teams
  teamServiceTypeMapping, // Predefined set of services by team
  serviceTypes,           // Predefined set of service types
  models,                 // Predefined set of AI models
  publications,           // Predefined set of publication types
  printPublications,    // Predefined set of print publications
  empty,                   // Definition for an 'empty' state or selection
  booleans,
  publicationStatusOptions,
  getServiceTypes
} from "../../definitions";

const PushToCue = () => {
  const initialState = () => EditorState.createEmpty();
  const [editorState, setEditorState] = useState(initialState);

  const articleComposer = getServiceTypes(serviceTypes, {
    specificServiceType: "Article Composer",
  })[0]?.value;

  const [input, setInput] = useState({
    additionalData: {
      category: "all",
      dateRange: "all",
      orderBy: "distance",
      publications: [],
      search: "",
    },
    articleData: {
      additionalContent: "",
      body: "",
      featureImageURL: "",
      leadText: "",
      metaDescription: "",
      newsLettersAndReferrersHeadline: "",
      metaTitle: "",
      tags: "",
      title: "",
    },
    cmsData: {
      cms: "mirror",
      cmsConfig: {},
      cmsContentType: articleTypes[15].value,
      cmsEntries: [],
      cmsPublicationStatus: "draft",
    },
    options: {
      max_length: lengths[1].value,
      messages: [],
      model: models[0].value,
      temperature: "",
      user: "",
    },
    settings: {
      articleTone: tones[0].value,
      language: languages[0].value,
      serviceType: articleComposer,
      writingStyle: "",
    },
  });
  const [loadingTitle, setLoadingTitle] = useState(false);
  const [loadingSocialHeadline, setLoadingSocialHeadline] = useState(false);
  const [loadingNewsLettersAndReferrersHeadline, setLoadingNewsLettersAndReferrersHeadline] = useState(false);
  const [loadingLeadText, setLoadingLeadText] = useState(false);
  const [loadingMetaDescription, setLoadingMetaDescription] = useState(false);
  const [sendingToCMS, setSendingToCMS] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    content: "",
    severity: "success",
  });
  const userProfile = useContext(userProfileContext);
  const location = useLocation();
  const configObject = location.state || {};
  let navigate = useNavigate();

  const setCharacterLimit = (serviceType, cms) => {
    // Default character limits
    let characterLimits = {
      title: 95,
      metaTitle: 80,
      newsLettersAndReferrersHeadline: 80,
      leadText: 300,
      metaDescription: 145,
    };

    // Custom character limits for "In Your Area"
    if (cms === "inyourarea") {
      characterLimits = {
        ...characterLimits,
        metaTitle: 50,
        leadText: 140,
        metaDescription: 135,
      };
    }

    return characterLimits[serviceType] || 4000; // Return a default value if the serviceType is not recognized
  }

  const regenerateContent = async (
    serviceType,
    setLoadingState,
    setResultState
  ) => {
    const editorContent = convertToRaw(editorState.getCurrentContent());
    const allText = editorContent.blocks.map(block => block.text).join('\n');

    // Update serviceType if input.cmsData.cms is "inyourarea"
    if (input.cmsData.cms === "inyourarea") {
      serviceType = `iya-${serviceType}`;
    }

    const max_length = setCharacterLimit(serviceType, input.cmsData.cms);
    const configOverrides = {
      message: allText,
      settings: {
        serviceType,
        writingStyle: input.cmsData.cms,
        articleTone: ""
      },
      options: {
        max_length: max_length,
        messages: [{ role: "user", content: allText }],
        tool_choice: 'none'
      },
    }
    const config = generateConfig(configOverrides);

    try {
      if (config.message.length > 0) {
        setLoadingState(true);

        const createChatCompletion = await completionsApi.sendMessageToCompletionsAPI(config);
        if (createChatCompletion) {
          let chatGeneration =
            typeof createChatCompletion.message === "string"
              ? createChatCompletion.message
              : null;

          if (chatGeneration !== null && chatGeneration !== "") {
            // Check and remove quotation marks and punctuation
            chatGeneration = cleanContent(chatGeneration, true);

            setResultState(chatGeneration);
          } else {
            setResultState(`An error occurred. Please try again.`);
          }
        }
        setLoadingState(false);
      }
    } catch (error) {
      const errorMessage = `Error: ${error?.toString() ?? "An error occurred. Please try again."}`;
      setResultState(errorMessage);
    } finally {
      setLoadingState(false);
    }
  };

  const prepareData = () => {
    const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const isFormComplete =
      body.trim() !== "" &&
      input.articleData.metaTitle.trim() !== "" &&
      input.articleData.newsLettersAndReferrersHeadline.trim() !== "" &&
      input.articleData.leadText.trim() !== "" &&
      input.articleData.metaDescription.trim() !== "" &&
      input.cmsData.cms !== null &&
      input.cmsData.cmsContentType !== null;

    if (!isFormComplete) {
      setSnackbarMessage({
        content: "Please fill in all the required fields.",
        severity: "error",
      });
      return;
    }
    setSendingToCMS(true);
    const updatedInput = {
      ...input,
      additionalData: {
        ...input.additionalData,
        serviceType: input.settings.serviceType
      },
      articleData: {
        ...input.articleData,
        body
      }
    };

    cmsApi
      .sendArticleToCMS(updatedInput)
      .then((response) => {
        setSendingToCMS(false);
        // Show a success message in the Snackbar
        if (response.contentURL) {
          setSnackbarMessage({
            content: response.contentURL,
            severity: "success",
          });
        } else {
          setSnackbarMessage({
            content: "Failed to create Content",
            severity: "error",
          });
        }
      })
      .catch((error) => {
        setSendingToCMS(false);
        console.log(error);
        // Show an error message in the Snackbar
        setSnackbarMessage({
          content: `Error sending article: ${error.message}`,
          severity: "error",
        });
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessage({ content: "", severity: "success" });
    navigate("/");
  };

  const setAndLoad = (loadingState, setState, value) => {
    if (value !== undefined && value !== null && value !== '') {
      loadingState(true);
      setState(value);
      loadingState(false);
    }
  };

  useEffect(() => {
    const { additionalData = {}, articleData = {}, cmsData = {}, options = {}, settings = {} } = configObject;

    if (articleData) {
      setAndLoad(setLoadingSocialHeadline, (val) => setInput(prevState => ({
        ...prevState,
        articleData: {
          ...prevState.articleData,
          metaTitle: val
        }
      })), articleData.metaTitle);
      setAndLoad(setLoadingLeadText, (val) => setInput(prevState => ({
        ...prevState,
        articleData: {
          ...prevState.articleData,
          leadText: val
        }
      })), articleData.leadText);
      setAndLoad(setLoadingTitle, (val) => setInput(prevState => ({
        ...prevState,
        articleData: {
          ...prevState.articleData,
          title: val
        }
      })), articleData.title);
      if (articleData.metaDescription) {
        setInput(prevState => ({
          ...prevState,
          articleData: {
            ...prevState.articleData,
            metaDescription: articleData.metaDescription
          }
        }));
      }

      if (articleData.featureImageURL) {
        setInput(prevState => ({
          ...prevState,
          articleData: {
            ...prevState.articleData,
            featureImageURL: articleData.featureImageURL
          }
        }));
      }

      if (articleData.additionalContent) {
        setInput(prevState => ({
          ...prevState,
          articleData: {
            ...prevState.articleData,
            additionalContent: articleData.additionalContent
          }
        }));
      }

      if (articleData.tags) {
        setInput(prevState => ({
          ...prevState,
          articleData: {
            ...prevState.articleData,
            tags: articleData.tags
          }
        }));
      }

      if (articleData.body) {
        const contentState = stateFromMarkdown(articleData.body);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    }

    if (additionalData) {
      setInput(prevState => ({
        ...prevState,
        additionalData: {
          ...prevState.additionalData,
          ...additionalData
        }
      }));
    }

    if (cmsData) {
      setInput(prevState => ({
        ...prevState,
        cmsData: {
          ...prevState.cmsData,
          ...cmsData
        }
      }));
    }

    if (options) {
      setInput(prevState => ({
        ...prevState,
        options: {
          ...prevState.options,
          ...options
        }
      }));
    }

    if (settings) {
      setInput(prevState => ({
        ...prevState,
        settings: {
          ...prevState.settings,
          ...settings
        }
      }));
    }
  }, [configObject]);

  return (
    <div>
      <Container maxWidth="xl">
        {/* Snackbar */}
        <Snackbar
          open={snackbarMessage.content !== ""}
          onClose={() =>
            setSnackbarMessage({ content: "", severity: "success" })
          }
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <div>
            {snackbarMessage.severity === "success" && (
              <Alert onClose={handleClose} severity={snackbarMessage.severity}>
                Article sent successfully.<br></br>
                <a
                  href={snackbarMessage.content}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {snackbarMessage.content}
                </a>
              </Alert>
            )}
            {snackbarMessage.severity === "error" && (
              <Alert
                onClose={() =>
                  setSnackbarMessage({ content: "", severity: "success" })
                }
                severity={snackbarMessage.severity}
              >
                {snackbarMessage.content}
              </Alert>
            )}
          </div>
        </Snackbar>
        <div className="ai-writer" style={{ height: "100%" }}>
          <Grid container spacing={2}>
            <Grid item sm={5}>
              {/* Pass the state values to PushToCueSelection */}
              <PushToCueSelection
                prepareData={prepareData}
                input={input}
                setInput={setInput}
                loadingMetaDescription={loadingMetaDescription}
                setLoadingMetaDescription={setLoadingMetaDescription}
                regenerateContent={regenerateContent}
                loadingSocialHeadline={loadingSocialHeadline}
                setLoadingSocialHeadline={setLoadingSocialHeadline}
                loadingNewsLettersAndReferrersHeadline={loadingNewsLettersAndReferrersHeadline}
                setLoadingNewsLettersAndReferrersHeadline={setLoadingNewsLettersAndReferrersHeadline}
                loadingLeadText={loadingLeadText}
                setLoadingLeadText={setLoadingLeadText}
                loadingTitle={loadingTitle}
                setLoadingTitle={setLoadingTitle}
                sendingToCMS={sendingToCMS}
              />
            </Grid>
            <Grid item sm={7}>
              <Texteditor
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default PushToCue;
