// Local Definitions from 'definitions' file
import {
  languages,
  lengths,
  temperatures,
  tones,
  teams,
  teamServiceTypeMapping,
  serviceTypes,
  batchServiceTypes,
  models,
  publications,
  empty,
  booleans,
  printPublications
} from "../../definitions";

const isValidOption = (obj, validOptions) => {
  if (typeof obj === 'string' || typeof obj === 'number') {
    const isValid = validOptions.some(option => option.value === obj);
    return isValid;
  }
  if (Array.isArray(obj)) {
    const isValid = obj.every(item => validOptions.some(option => option.value === item));
    return isValid;
  }
  if (typeof obj === 'object' && obj !== null) {
    const isValid = validOptions.some(option => option.value === obj.value);
    return isValid;
  }
  return false;
};

const isBooleanOption = (obj) => {
  const isValid = obj !== undefined && booleans.some(option => option.value === obj);
  return isValid;
};

const isEmptyOption = (obj) => {
  const isValid = obj !== undefined && (typeof obj === 'string' || empty.some(option => option.value === obj));
  return isValid;
};

const sanitiseOptions = (options) => {
  if (!options) return {};
  const sanitisedOptions = {};
  if (isValidOption(options.model, models)) sanitisedOptions.model = options.model;
  if (isValidOption(options.temperature, temperatures)) sanitisedOptions.temperature = options.temperature;
  if (isValidOption(options.max_length, lengths)) sanitisedOptions.max_length = options.max_length;
  if (Array.isArray(options.messages)) sanitisedOptions.messages = options.messages;
  if (isBooleanOption(options.suggestionsEnabled)) sanitisedOptions.suggestionsEnabled = options.suggestionsEnabled; // Use isBooleanOption for suggestionsEnabled
  return sanitisedOptions;
};

const sanitiseSettingsFields = (settings) => {
  if (!settings) return {};
  const sanitisedSettings = {};
  if (isValidOption(settings.language, languages)) sanitisedSettings.language = settings.language;
  if (isValidOption(settings.articleTone, tones) || isEmptyOption(settings.articleTone)) sanitisedSettings.articleTone = settings.articleTone;
  if (isValidOption(settings.writingStyle, publications) || isEmptyOption(settings.writingStyle)) sanitisedSettings.writingStyle = settings.writingStyle;
  if (isValidOption(settings.serviceType, serviceTypes) || isValidOption(settings.serviceType, batchServiceTypes)) sanitisedSettings.serviceType = settings.serviceType;
  if (isValidOption(settings.secondServiceType, serviceTypes) || isValidOption(settings.secondServiceType, batchServiceTypes)) sanitisedSettings.secondServiceType = settings.secondServiceType;
  if (isValidOption(settings.thirdServiceType, serviceTypes) || isValidOption(settings.thirdServiceType, batchServiceTypes)) sanitisedSettings.thirdServiceType = settings.thirdServiceType;
  if (isValidOption(settings.team, teams)) sanitisedSettings.team = settings.team;
  return sanitisedSettings;
};

const sanitiseAdditionalData = (additionalData) => {
  if (!additionalData) return {};
  const sanitisedAdditionalData = {};
  if (additionalData.search && typeof additionalData.search === 'string') sanitisedAdditionalData.search = additionalData.search;
  if (isValidOption(additionalData.publications, printPublications.map(pub => ({ value: pub.printTitle })))) sanitisedAdditionalData.publications = additionalData.publications;
  if (isValidOption(additionalData.dateRange, [
    { value: 'all' }, { value: 'today' }, { value: 'yesterday' }, { value: 'this_week' },
    { value: 'last_week' }, { value: 'this_month' }, { value: 'last_month' }
  ])) sanitisedAdditionalData.dateRange = additionalData.dateRange;
  if (isValidOption(additionalData.orderBy, [
    { value: 'publishedTime' }, { value: 'distance' }
  ])) sanitisedAdditionalData.orderBy = additionalData.orderBy;
  if (isValidOption(additionalData.category, [
    { value: 'all' }, { value: 'planning' }, { value: 'traffic' }, { value: 'hgv' },
    { value: 'licensing' }, { value: 'probate' }, { value: 'legal' }, { value: 'statutory' }, { value: 'other' }
  ])) sanitisedAdditionalData.category = additionalData.category;
  return sanitisedAdditionalData;
};

const sanitiseSettings = (settings) => {
  const sanitised = {};
  if (settings.options !== undefined) {
    const sanitisedOptions = sanitiseOptions(settings.options);
    if (Object.keys(sanitisedOptions).length > 0) sanitised.options = sanitisedOptions;
  }
  if (settings.settings !== undefined) {
    const sanitisedSettingsFields = sanitiseSettingsFields(settings.settings);
    if (Object.keys(sanitisedSettingsFields).length > 0) sanitised.settings = sanitisedSettingsFields;
  }
  if (settings.additionalData !== undefined) {
    const sanitisedAdditionalData = sanitiseAdditionalData(settings.additionalData);
    if (Object.keys(sanitisedAdditionalData).length > 0) sanitised.additionalData = sanitisedAdditionalData;
  }
  if (settings.expanded !== undefined && isBooleanOption(settings.expanded)) sanitised.expanded = settings.expanded;
  if (settings.useQueue !== undefined && isBooleanOption(settings.useQueue)) sanitised.useQueue = settings.useQueue;

  return sanitised;
};

export default sanitiseSettings;