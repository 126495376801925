// React Libraries and Hooks
import React, { useState, useEffect } from 'react';

// Material UI Components
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Local Definitions from 'definitions' file
import {
  languages,
  lengths,
  temperatures,
  tones,
  teams,
  teamServiceTypeMapping,
  serviceTypes,
  batchServiceTypes,
  models,
  publications,
  empty,
  booleans
} from "../../definitions";

// Local Components
import CustomOptions from './CustomOptions';

// Import validation function
import sanitiseSettings from './settingsValidation';

const AdvancedOptions = ({
  serviceMode = "chat",
  input,
  setInput
}) => {
  // Initially check localStorage to see if the accordion should be expanded
  const localStorageKey = `scribe-${serviceMode}-options`;
  const savedSettings = JSON.parse(localStorage.getItem(localStorageKey) || '{}');

  // Determine if the accordion should be expanded by default either because it was previously expanded
  const isExpandedByDefault = savedSettings.expanded;

  // Set the initial state based on the above logic
  const [expanded, setExpanded] = useState(isExpandedByDefault ? 'settingsPanel' : false);

  useEffect(() => {
    const savedSettings = localStorage.getItem(localStorageKey);
    if (savedSettings) {
      try {
        const settings = JSON.parse(savedSettings);
        const sanitisedSettings = sanitiseSettings(settings);

        const updatedOptions = sanitisedSettings.options || input.option;
        const updatedSettings = sanitisedSettings.settings || input.settings;

        localStorage.setItem(localStorageKey, JSON.stringify(sanitisedSettings)); // Update localStorage with sanitised settings

        setInput(prevState => ({
          ...prevState,
          ...sanitisedSettings,
          options: { ...prevState.options, ...updatedOptions },
          settings: { ...prevState.settings, ...updatedSettings },
        }));
      } catch (error) {
        console.error('Error parsing or sanitising settings:', error);
      }
    }
  }, []);

  const handleChange = (field, value, nestedField = null) => {
    const newValue = value && typeof value === 'object' && value.hasOwnProperty('value') ? value.value : value;

    setInput(prevInput => {
      const updatedInput = { ...prevInput };
      const updatedSettings = JSON.parse(localStorage.getItem(localStorageKey) || '{}');

      if (field === 'systemMessage') {
        const systemMessageIndex = updatedInput.options.messages.findIndex(msg => msg.role === 'system');

        if (newValue === '') {
          if (systemMessageIndex >= 0) {
            updatedInput.options.messages.splice(systemMessageIndex, 1);
          }
          // Remove the system message from localStorage
          if (updatedSettings.options && updatedSettings.options.messages) {
            updatedSettings.options.messages = updatedSettings.options.messages.filter(msg => msg.role !== 'system');
          }
        } else {
          if (systemMessageIndex >= 0) {
            updatedInput.options.messages[systemMessageIndex].content = newValue;
          } else {
            updatedInput.options.messages.unshift({ role: 'system', content: newValue });
          }
          updatedSettings.options = { ...updatedSettings.options, messages: [{ role: 'system', content: newValue }] };
        }
      } else if (nestedField) {
        updatedInput[nestedField][field] = newValue;
        updatedSettings[nestedField] = { ...updatedSettings[nestedField], [field]: newValue };
      } else {
        updatedInput[field] = newValue;
        updatedSettings[field] = newValue;
      }

      if (field === 'team') {
        const newFilteredServiceTypes = teamServiceTypeMapping[newValue] || serviceTypes.map(st => st.value);
        if (!newFilteredServiceTypes.includes(updatedInput.settings.serviceType)) {
          const newServiceTypeValue = newFilteredServiceTypes.length > 0 ? newFilteredServiceTypes[0] : '';
          updatedInput.settings.serviceType = newServiceTypeValue;
          updatedSettings.settings.serviceType = newServiceTypeValue;
        }
      }

      localStorage.setItem(localStorageKey, JSON.stringify(updatedSettings));
      return updatedInput;
    });
  };

  // Generate filtered service types based on selected team and serviceMode
  const filteredServiceTypes = (() => {
    // Select the appropriate service list
    const allServices = serviceMode === "batch-processor" ? batchServiceTypes : serviceTypes;

    // If a team is selected, filter services by that team's mapping
    if (input.settings.team && teamServiceTypeMapping[input.settings.team]) {
      const teamServices = teamServiceTypeMapping[input.settings.team];

      // Map team services to their full details (including image and description) from allServices
      return teamServices
        .map((serviceName) => allServices.find((service) => service.value === serviceName))
        .filter(Boolean) // Remove undefined values (services not found)
        .map(({ value, label, description, image }) => ({
          value,
          label,
          description: description || null, // Include description if available
          image: image || null,             // Include image if available
        }));
    }

    // If no team is selected, return all available services
    return allServices.map(({ value, label, description, image }) => ({
      value,
      label,
      description: description || null, // Include description if available
      image: image || null,             // Include image if available
    }));
  })();

  const teamOptions = [
    {
      type: 'autocomplete',
      label: 'Team',
      value: teams.find(option => option.value === input.settings.team) || null,
      onChange: (newValue) => handleChange('team', newValue ? newValue.value : '', 'settings'),
      options: teams,
      tooltipTitle: 'Selecting a team will tailor the available services to best suit the collaboration needs of the chosen group. Ensure you pick the right team to access the most relevant tools and features.',
      disableClearable: true,
      fullWidth: true,
      sx: { mb: 2 },
      gridSize: { xs: 12 }
    },
  ];

  let serviceTypeOptions = [
    {
      type: 'richautocomplete',
      label: 'Service',
      value: filteredServiceTypes.find(option => option.value === input.settings.serviceType) || null,
      onChange: (newValue) => handleChange('serviceType', newValue ? newValue.value : '', 'settings'),
      options: filteredServiceTypes,
      tooltipTitle: 'Select the type of service you wish to use to customise your AI experience. Each service offers unique features and capabilities designed to enhance your interaction.',
      fullWidth: true,
      sx: { mb: 2 },
      gridSize: { xs: 12 }
    }
  ];

  if (input.settings.secondServiceType) {
    serviceTypeOptions.push({
      type: 'autocomplete',
      label: 'Service Type for optional second aggregated column',
      value: filteredServiceTypes.find(option => option.value === input.settings.secondServiceType) || null,
      onChange: (newValue) => handleChange('secondServiceType', newValue ? newValue.value : '', 'settings'),
      options: filteredServiceTypes,
      tooltipTitle: 'Select the type of service you wish to use to customise your AI experience. Each service offers unique features and capabilities designed to enhance your interaction.',
      fullWidth: true,
      sx: { mb: 2 },
      gridSize: { xs: 12 }
    });
  }

  if (input.settings.thirdServiceType) {
    serviceTypeOptions.push({
      type: 'autocomplete',
      label: 'Service Type for optional third aggregated column',
      value: filteredServiceTypes.find(option => option.value === input.settings.thirdServiceType) || null,
      onChange: (newValue) => handleChange('thirdServiceType', newValue ? newValue.value : '', 'settings'),
      options: filteredServiceTypes,
      tooltipTitle: 'Select the type of service you wish to use to customise your AI experience. Each service offers unique features and capabilities designed to enhance your interaction.',
      fullWidth: true,
      sx: { mb: 2 },
      gridSize: { xs: 12 }
    });
  }

  let options = [
    {
      type: 'autocomplete',
      label: 'Language',
      value: languages.find(option => option.value === input.settings.language) || null,
      onChange: (newValue) => handleChange('language', newValue ? newValue.value : '', 'settings'),
      options: languages,
      tooltipTitle: 'Choosing a language will determine the processing language for your inputs and outputs, ensuring accuracy in understanding and response generation.'
    },
    {
      type: 'autocomplete',
      label: 'Model',
      value: models.find(option => option.value === input.options.model) || null,
      onChange: (newValue) => handleChange('model', newValue ? newValue.value : '', 'options'),
      options: models,
      tooltipTitle: 'Select from various models tailored for different text processing capabilities. Each model has unique strengths, from general conversations to specialised topics.'
    },
    {
      type: 'autocomplete',
      label: 'Temperature',
      value: temperatures.find(option => option.value === input.options.temperature) || null,
      onChange: (newValue) => handleChange('temperature', newValue ? newValue.value : '', 'options'),
      options: temperatures,
      tooltipTitle: 'Temperature settings control the creativity and unpredictability of responses. A lower value means more deterministic outputs, while a higher value encourages diverse and creative answers.'
    },
    {
      type: 'autocomplete',
      label: 'Length',
      value: lengths.find(option => option.value === input.options.max_length) || null,
      onChange: (newValue) => handleChange('max_length', newValue ? newValue.value : '', 'options'),
      options: lengths,
      tooltipTitle: 'Control the verbosity of the AIs responses. Choose a longer length for detailed answers or a shorter one for concise replies.'
    },
    {
      type: 'autocomplete',
      label: 'Tone',
      value: tones.find(option => option.value === input.settings.articleTone) || null,
      onChange: (newValue) => {
        handleChange('articleTone', newValue ? newValue.value : '', 'settings');
        handleChange('writingStyle', empty.value, 'settings');
      },
      options: tones,
      tooltipTitle: 'Select a tone to set the mood and style of the content. This helps in tailoring the text to suit the intended audience and purpose.',
      disableClearable: false
    },
    {
      type: 'autocomplete',
      label: 'Publication Tone',
      value: publications.find(option => option.value === input.settings.writingStyle) || null,
      onChange: (newValue) => {
        handleChange('writingStyle', newValue ? newValue.value : '', 'settings');
        handleChange('articleTone', empty.value, 'settings');
      },
      options: publications,
      tooltipTitle: 'This adjusts the overall tone for published content, aligning it with your brand or publication’s voice. It influences how your message is perceived by readers.',
      disableClearable: false
    },
    {
      type: 'text',
      label: 'Custom Service Message Override',
      value: input.options.messages.find(msg => msg.role === 'system')?.content || '',
      onChange: (newValue) => handleChange('systemMessage', newValue),
      options: [], // Empty array signifies a text input in this context
      tooltipTitle: 'Enter a custom service message that will override the default service type. This allows you to provide precise instructions or information, guiding the assistants responses to better suit your needs.'
    }
  ];

  if (input.useQueue !== undefined) {
    options.splice(options.length - 1, 0,
      {
        type: 'autocomplete',
        label: 'Use Queue-based Processing',
        value: booleans.find(option => option.value === input.useQueue) || null,
        onChange: (newValue) => handleChange('useQueue', newValue ? newValue.value : ''),
        options: booleans,
        tooltipTitle: 'Use the asynchronous queue-based version of the API to process the content.'
      });
  }

  if (input.options.suggestionsEnabled !== undefined) {
    options.push({
      type: 'switch',
      label: 'Enable Suggested Prompt',
      value: input.options.suggestionsEnabled,
      onChange: (newValue) => handleChange('suggestionsEnabled', newValue, 'options'),
      tooltipTitle: 'Enable or disable suggested prompts for better user interactions.',
      fullWidth: true,
      gridSize: { xs: 12 },
    });
  }

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);

    // Save the expanded state to localStorage
    const updatedSettings = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
    updatedSettings.expanded = isExpanded; // Update the flag based on accordion's state
    localStorage.setItem(localStorageKey, JSON.stringify(updatedSettings));
  };

  return (
    <>
      {input.settings.team && <CustomOptions options={teamOptions} />}
      <CustomOptions options={serviceTypeOptions} />
      <Accordion expanded={expanded === 'settingsPanel'} onChange={handleAccordionChange('settingsPanel')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>Advanced Options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomOptions options={options} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AdvancedOptions;
