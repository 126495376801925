const defaultConfig = {
  id: null,
  product: "ai-chat",
  settings: {
    serviceType: "Article Composer",
    language: "English (British English)",
    articleTone: "Informative",
    writingStyle: "",
  },
  options: {
    frequency_penalty: 0.2,
    max_length: null,
    messages: [],
    model: "gpt-4",
    presence_penalty: 0.2,
    response_format: { "type": "text" },
    temperature: 0.5,
    n: 1,
    user: null,
    tool_choice: 'auto',
    suggestionsEnabled: false
  },
  user_id: null,
  user_name: null
};

const deepMerge = (target, source) => {
  for (const key in source) {
    if (source[key] instanceof Object && target[key] instanceof Object) {
      Object.assign(source[key], deepMerge(target[key], source[key]));
    } else {
      target[key] = source[key];
    }
  }

  return { ...target, ...source };
};

const validateAndFormatTemperature = (temperature) => {
  let formattedTemp = parseFloat(temperature);
  if (isNaN(formattedTemp) || formattedTemp < 0 || formattedTemp > 1) {
    console.warn('Temperature value is invalid, setting to default 0.5');
    formattedTemp = 0.5; // Default value if invalid
  }
  return formattedTemp;
};

export const generateConfig = (configOverrides = {}, enableMergedSettings = true) => {
  // Check if there are saved settings in localStorage
  const savedSettings = JSON.parse(localStorage.getItem("settings")) || {};

  // Extract modelId from savedSettings or use the default modelId if not present
  const modelIdFromSavedSettings = savedSettings.modelId || defaultConfig.options.model;

  // Remove modelId from savedSettings so it doesnt get saved in the db in the wrong place
  if (savedSettings?.modelId) {
    delete savedSettings.modelId;
  }

  // Ensure temperature is in correct format for savedSettings at the top level
  if (savedSettings.temperature !== undefined) {
    savedSettings.temperature = validateAndFormatTemperature(savedSettings.temperature);
  }

  // Ensure temperature is in correct format for configOverrides, if present
  if (configOverrides.options && configOverrides.options.temperature !== undefined) {
    configOverrides.options.temperature = validateAndFormatTemperature(configOverrides.options.temperature);
  }

  const resolveToolChoice = () => {
    const defaultToolChoice = defaultConfig.options.tool_choice;
    const overrideToolChoice = configOverrides.options?.tool_choice;
    const savedToolChoice = savedSettings.options?.tool_choice;

    // Ensure tool_choice is either a string or a valid object
    const validToolChoice = (choice) =>
      typeof choice === "string" || (typeof choice === "object" && "function" in choice);

    if (validToolChoice(overrideToolChoice)) return overrideToolChoice;
    if (validToolChoice(savedToolChoice)) return savedToolChoice;
    return defaultToolChoice;
  };

  // Resolve the `tool_choice` value by prioritising the following order:
  // 1. If provided in `configOverrides.options`, it takes the highest priority.
  // 2. If not found in `configOverrides`, check for a saved value in `savedSettings.options`.
  // 3. If neither `configOverrides` nor `savedSettings` specifies a `tool_choice`, 
  //    fall back to the default value from `defaultConfig.options`.
  // The `resolveToolChoice` function ensures that `tool_choice` is always a valid string
  // or an object with a `function` property, preventing runtime errors during deep merging.
  const toolChoice = resolveToolChoice();

  // Merge savedSettings.settings and configOverrides.settings, with configOverrides.settings taking precedence
  const mergedSettings = deepMerge(savedSettings, configOverrides.settings);

  // Merge defaultConfig with configOverrides, with configOverrides taking precedence
  const mergedConfig = deepMerge(defaultConfig, configOverrides);

  // Update tool_choic
  mergedConfig.options.tool_choice = toolChoice;

  // Update modelId in mergedConfig.model.id
  mergedConfig.options.model = modelIdFromSavedSettings;

  // Merge mergedConfig with the already merged settings
  const finalConfig = {
    ...mergedConfig,
    settings: enableMergedSettings ? { ...defaultConfig.settings, ...mergedSettings } : { ...mergedConfig.settings },
  };

  return finalConfig;
};
