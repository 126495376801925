export const services = [
  {
    "value": "Ad Copy Generation",
    "label": "Ad Copy Generation",
    "image": "",
    "description": "Write impactful and persuasive copy for advertisements.",
    "prompts": [
      "Generate ad copy for a fashion brand.",
      "Write a short and impactful ad for Google Ads.",
      "How do I make my ad copy more engaging?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Amazon Affiliate Offer Article Generator",
    "label": "Amazon Affiliate Offer Article Generator",
    "image": "",
    "description": "Generate articles tailored for Amazon affiliate offers.",
    "prompts": [
      "Write an article for an Amazon affiliate offer.",
      "How do I optimise content for Amazon affiliate products?",
      "Generate engaging content for an Amazon affiliate campaign."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Applicant Feedback",
    "label": "Applicant Feedback",
    "image": "",
    "description": "Provide constructive feedback to job applicants.",
    "prompts": [
      "Draft feedback for a candidate who didn’t get the job.",
      "How do I provide encouraging feedback to a rejected applicant?",
      "Generate a response template for applicant queries."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Article Adapter",
    "label": "Article Adapter",
    "image": "",
    "description": "Adapt articles to different formats or target audiences.",
    "prompts": [
      "Adapt this article for a younger audience.",
      "Rewrite this blog post for LinkedIn.",
      "How can I adapt this content for a professional audience?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Article Composer",
    "label": "Article Composer",
    "image": "",
    "description": "Write well-structured articles on any topic.",
    "prompts": [
      "Write an article about climate change.",
      "Summarise the latest tech trends.",
      "Generate a long-form article on health tips."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Article Rewriter",
    "label": "Article Rewriter",
    "image": "",
    "description": "Rewrite existing articles for improved clarity or tone.",
    "prompts": [
      "Rewrite this article to make it more engaging.",
      "Paraphrase this content for a new audience.",
      "How can I rewrite this article for better readability?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "tags",
    "label": "Article Tags",
    "image": "",
    "description": "Generate relevant tags for articles to improve discoverability.",
    "prompts": [
      "What are the best tags for this article?",
      "Generate relevant keywords as tags for this content.",
      "Suggest tags to categorise this article effectively."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "title",
    "label": "Article Title",
    "image": "",
    "description": "Generate creative and effective titles for articles.",
    "prompts": [
      "Suggest an eye-catching title for this article.",
      "What’s a compelling title for this content?",
      "Generate an optimised title for this blog post."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "Audience Segmentation Analysis",
    "label": "Audience Segmentation Analysis",
    "image": "",
    "description": "Segment your audience to create targeted strategies.",
    "prompts": [
      "Analyse audience segments for a social media campaign.",
      "How do I identify key demographics for my product?",
      "Generate a segmentation analysis for an e-commerce website."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Automated Testing",
    "label": "Automated Testing",
    "image": "",
    "description": "Generate and manage automated tests for software.",
    "prompts": [
      "Write a test case for user authentication.",
      "How do I automate regression testing for my app?",
      "Generate a unit test for this function in Java."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "AWIN Affiliate Offer Article Generator",
    "label": "AWIN Affiliate Offer Article Generator",
    "image": "",
    "description": "Generate affiliate offer articles for AWIN campaigns.",
    "prompts": [
      "Generate an article for an AWIN affiliate offer.",
      "How do I write an affiliate article for AWIN?",
      "Create engaging content for an AWIN affiliate product."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Board Meeting Presentation",
    "label": "Board Meeting Presentation",
    "image": "",
    "description": "Create professional presentations for board meetings.",
    "prompts": [
      "Draft a presentation for the next board meeting.",
      "What should I include in a financial performance slide?",
      "Generate slides for a business growth strategy proposal."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Brand Messaging",
    "label": "Brand Messaging",
    "image": "",
    "description": "Define and craft consistent messaging for your brand.",
    "prompts": [
      "Define the brand messaging for a tech startup.",
      "How do I create a consistent brand voice?",
      "Generate key messaging for a new product launch."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Bug Tracking and Resolution",
    "label": "Bug Tracking and Resolution",
    "image": "",
    "description": "Track and resolve software bugs efficiently.",
    "prompts": [
      "Help me diagnose this issue in my code.",
      "How do I prioritise bugs for resolution?",
      "Generate a report for our bug tracking system."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Campaign Brief",
    "label": "Campaign Brief",
    "image": "",
    "description": "Generate a detailed campaign brief tailored to your audience.",
    "prompts": [
      "Create a campaign brief for a product launch.",
      "What should I include in a digital marketing strategy?",
      "Generate ideas for a new advertising campaign."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Campaign Effectiveness Analysis",
    "label": "Campaign Effectiveness Analysis",
    "image": "",
    "description": "Evaluate the success of marketing campaigns.",
    "prompts": [
      "Analyse the effectiveness of our latest email campaign.",
      "What metrics should I use to measure campaign success?",
      "Generate insights on a recent advertising campaign's ROI."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Chat",
    "label": "Chat",
    "image": "",
    "description": "A intelligent AI assistant, here to help you with anything you need.",
    "prompts": [
      "Tell me about the latest advancements in technology.",
      "Suggest ideas for a creative marketing campaign.",
      "Help me brainstorm a title for an article.",
      "How can I improve my work-life balance?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Churn Prediction",
    "label": "Churn Prediction",
    "image": "",
    "description": "Predict customer churn to improve retention strategies.",
    "prompts": [
      "Predict which customers are at risk of churning.",
      "What factors contribute to customer churn in our industry?",
      "Generate a churn analysis report for our subscription service."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Client Proposal",
    "label": "Client Proposal",
    "image": "",
    "description": "Create professional proposals tailored to client needs.",
    "prompts": [
      "Draft a client proposal for a new project.",
      "How can I make my client proposal more compelling?",
      "Generate a proposal template for a marketing campaign."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Code Review",
    "label": "Code Review",
    "image": "",
    "description": "Analyse and review code for quality and efficiency.",
    "prompts": [
      "Review this Python script for best practices.",
      "What improvements can I make to this function?",
      "Generate feedback for a code review session."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Coding Assistance",
    "label": "Coding Assistance",
    "image": "",
    "description": "Get help with coding, debugging, and optimisation.",
    "prompts": [
      "Write a function to sort an array in Python.",
      "How do I debug this JavaScript error?",
      "Optimise this SQL query for better performance."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Commenting Article Generator",
    "label": "Commenting Article Generator",
    "image": "",
    "description": "Generate articles designed for engaging online discussions.",
    "prompts": [
      "Write an article encouraging comments about climate change.",
      "How do I create content that sparks discussion?",
      "Generate an opinion piece for community engagement."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Commercial Content Editing",
    "label": "Commercial Content Editing",
    "image": "",
    "description": "Edit and optimise commercial content for better engagement.",
    "prompts": [
      "Edit this commercial article for better readability.",
      "How can I optimise this content for engagement?",
      "Revise this article to align with the brand's voice."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Competitor Analysis",
    "label": "Competitor Analysis",
    "image": "",
    "description": "Analyse competitors and identify market opportunities.",
    "prompts": [
      "Analyse the strengths and weaknesses of our competitors.",
      "Generate a competitor analysis for a tech startup.",
      "What are the key factors to consider in a competitor analysis?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Competitor Intelligence",
    "label": "Competitor Intelligence",
    "image": "",
    "description": "Gather insights about competitors to guide business strategies.",
    "prompts": [
      "Analyse the strengths and weaknesses of our competitors.",
      "What are the market positions of our top competitors?",
      "Generate a report on competitor product pricing strategies."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Consent Management",
    "label": "Consent Management",
    "image": "",
    "description": "Manage and verify consent for legal or regulatory purposes.",
    "prompts": [
      "Create a consent form for data collection.",
      "How do I manage user consent for cookies?",
      "Generate a template for consent withdrawal."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Content and Headline Analysis",
    "label": "Content and Headline Analysis",
    "image": "",
    "description": "Analyse and optimise your content and headlines for impact.",
    "prompts": [
      "Analyse this headline for effectiveness.",
      "How can I improve this content for SEO?",
      "Optimise this headline for better engagement."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Content Performance Metrics",
    "label": "Content Performance Metrics",
    "image": "",
    "description": "Measure and analyse the performance of your content.",
    "prompts": [
      "What are the performance metrics for my latest blog post?",
      "How do I track engagement metrics for a social media campaign?",
      "Generate a report on content performance for Q1."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Content Planning",
    "label": "Content Planning",
    "image": "",
    "description": "Plan and strategise your content creation process.",
    "prompts": [
      "Help me plan content for a social media campaign.",
      "What should I include in my content strategy?",
      "Generate a content plan for a new product launch."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Continuous Integration and Deployment",
    "label": "Continuous Integration and Deployment",
    "image": "",
    "description": "Set up CI/CD pipelines for software projects.",
    "prompts": [
      "How do I set up a CI/CD pipeline with Jenkins?",
      "Generate a guide for implementing CI/CD in GitHub Actions.",
      "What are the key benefits of continuous deployment?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Copywriter",
    "label": "Copywriter",
    "image": "",
    "description": "Craft compelling copy for articles, marketing, ads, or content.",
    "prompts": [
      "Write an introduction for my article.",
      "Create engaging content for a marketing campaign.",
      "Draft an article opening about AI innovation.",
      "Write an email promoting our new product.",
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Creative Ad Product Description",
    "label": "Creative Ad Product Description",
    "image": "",
    "description": "Write creative and engaging product descriptions for ads.",
    "prompts": [
      "Write a creative product description for a new gadget.",
      "How do I describe this product for an ad?",
      "Generate a compelling description for an ad campaign."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Customer Behavior Insights",
    "label": "Customer Behavior Insights",
    "image": "",
    "description": "Understand customer behavior to improve engagement.",
    "prompts": [
      "Analyse customer behavior trends for our e-commerce site.",
      "What are the key behaviors of our target audience?",
      "Generate insights on customer purchasing patterns."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Data Analysis and Trends Finder",
    "label": "Data Analysis and Trends Finder",
    "image": "",
    "description": "Analyse data trends and uncover actionable insights.",
    "prompts": [
      "Analyse data trends for the past quarter.",
      "What are the emerging trends in digital marketing?",
      "Generate insights from this dataset."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Deployment Automation",
    "label": "Deployment Automation",
    "image": "",
    "description": "Automate the deployment of software applications.",
    "prompts": [
      "Create a script to automate app deployment.",
      "What tools should I use for deployment automation?",
      "Generate a checklist for deploying a web application."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Digital Advertising Strategy",
    "label": "Digital Advertising Strategy",
    "image": "",
    "description": "Develop strategies to optimise your digital advertising.",
    "prompts": [
      "Develop a strategy for a Facebook ad campaign.",
      "What are the latest trends in digital advertising?",
      "Generate a strategy for programmatic advertising."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Editorial Calendar",
    "label": "Editorial Calendar",
    "image": "",
    "description": "Plan and manage your editorial content calendar.",
    "prompts": [
      "Help me create an editorial calendar for next month.",
      "What topics should I include in my content schedule?",
      "Generate a calendar plan for a tech blog."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Email Composer and Responder",
    "label": "Email Composer and Responder",
    "image": "",
    "description": "Write and respond to emails effectively.",
    "prompts": [
      "Draft an email introducing our new product to potential clients.",
      "How do I respond to a client inquiry professionally?",
      "Generate an email template for a follow-up after a sales call."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Executive Briefing",
    "label": "Executive Briefing",
    "image": "",
    "description": "Prepare concise and impactful briefings for executives.",
    "prompts": [
      "Summarise the latest industry trends for an executive briefing.",
      "What key points should I include in a briefing for the board?",
      "Generate a briefing document for a leadership team meeting."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Feature Prioritisation",
    "label": "Feature Prioritisation",
    "image": "",
    "description": "Prioritise features based on impact and feasibility.",
    "prompts": [
      "Help me prioritise features for the next product release.",
      "What criteria should I use for feature prioritisation?",
      "Generate a priority list for these features."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Financial Analysis",
    "label": "Financial Analysis",
    "image": "",
    "description": "Analyse financial data to support informed decisions.",
    "prompts": [
      "Analyse the financial performance of our company last quarter.",
      "What are the key financial ratios for this industry?",
      "Generate a report on cash flow analysis."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Football Match Report",
    "label": "Football Match Report",
    "image": "",
    "description": "Write detailed reports on football matches.",
    "prompts": [
      "Write a match report for last night's game.",
      "How do I summarise key moments in a football match?",
      "Generate a report on the latest Premier League match."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "GDPR Compliance Check",
    "label": "GDPR Compliance Check",
    "image": "",
    "description": "Check for compliance with GDPR regulations.",
    "prompts": [
      "How do I ensure GDPR compliance for my website?",
      "Generate a checklist for GDPR compliance.",
      "What are the key points of GDPR for data collection?"
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Headline Analysis and Optimisation",
    "label": "Headline Analysis and Optimisation",
    "image": "",
    "description": "Analyse and optimise headlines for better performance.",
    "prompts": [
      "Analyse this headline: 'The Future of AI'.",
      "Optimise my headline for SEO and engagement.",
      "How can I make my headline more attention-grabbing?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Headline Topic and Theme Analyser",
    "label": "Headline Topic and Theme Analyser",
    "image": "",
    "description": "Analyse headlines or URLs to identify topics/themes, quantify frequency, and present structured insights.",
    "prompts": [
      "Analyse these headlines for topics/themes: ['Breaking: AI breakthrough announced', 'New healthcare policies spark debate', 'Championship game ends in dramatic fashion'].",
      "Provide a CSV with assigned topics/themes for this list of URLs.",
      "What are the most frequent topics/themes in this dataset of headlines?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "HR Comms",
    "label": "HR Comms",
    "image": "",
    "description": "Generate clear and effective HR communications.",
    "prompts": [
      "Draft an email to employees about upcoming training.",
      "How do I write an HR policy update for all staff?",
      "Generate a message to welcome new hires."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "HR FAQ Generator",
    "label": "HR FAQ Generator",
    "image": "",
    "description": "Create a list of frequently asked questions for HR.",
    "prompts": [
      "Generate FAQs about employee benefits.",
      "What are common HR-related questions about leave policies?",
      "Draft an FAQ for new hires regarding company policies."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "HR Meeting/Interview Questions",
    "label": "HR Meeting/Interview Questions",
    "image": "",
    "description": "Generate relevant questions for HR meetings and interviews.",
    "prompts": [
      "What are some good interview questions for a marketing role?",
      "Generate questions for a performance review meeting.",
      "How do I prepare questions for a candidate skills assessment?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "HR Policy Compliance",
    "label": "HR Policy Compliance",
    "image": "",
    "description": "Ensure compliance with HR policies and regulations.",
    "prompts": [
      "How do I ensure compliance with labor laws?",
      "Generate a checklist for HR policy compliance.",
      "What are the key elements of a compliant leave policy?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Image Generator",
    "label": "Image Generator",
    "image": "",
    "description": "Generate unique and creative images using AI.",
    "prompts": [
      "Create an image for a futuristic cityscape.",
      "Generate a visual for a new product concept.",
      "How do I create an illustration for a social media post?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Innovation Ideation",
    "label": "Innovation Ideation",
    "image": "",
    "description": "Generate innovative ideas for software development.",
    "prompts": [
      "Help me brainstorm features for a productivity app.",
      "What are some innovative ideas for e-commerce platforms?",
      "Generate unique concepts for a social networking app."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Interview or Speech to Article",
    "label": "Interview or Speech to Article",
    "image": "",
    "description": "Convert interviews or speeches into readable articles.",
    "prompts": [
      "Write an article based on this interview transcript.",
      "Summarise this speech into an engaging article.",
      "How can I structure an article from this interview?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Investor Relations Brief",
    "label": "Investor Relations Brief",
    "image": "",
    "description": "Prepare concise briefs for investor communications.",
    "prompts": [
      "Draft a brief for our quarterly earnings report.",
      "What key points should I include in an investor update?",
      "Generate a summary for a shareholder meeting presentation."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Jarvis Assistant",
    "label": "Jarvis Assistant",
    "image": "",
    "description": "A quirky and intelligent AI assistant, inspired by Iron Man’s Jarvis, here to help with anything you need.",
    "prompts": [
      "What’s the quickest way to solve this problem?",
      "Can you tell me something interesting about the universe?",
      "Help me draft a witty response to this email.",
      "What’s the best way to organise my day efficiently?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Jira Ticket Creator",
    "label": "Jira Ticket Creator",
    "image": "",
    "description": "Generate detailed Jira tickets for tasks and projects.",
    "prompts": [
      "Create a Jira ticket for a new feature request.",
      "Generate a ticket for a bug fix.",
      "Summarise this issue for Jira."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Job Advert Generator",
    "label": "Job Advert Generator",
    "image": "",
    "description": "Write engaging job advertisements to attract candidates.",
    "prompts": [
      "Draft a job advert for a senior developer position.",
      "What makes a job advert more appealing to candidates?",
      "Generate an ad for a sales associate role."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Job Description Generator",
    "label": "Job Description Generator",
    "image": "",
    "description": "Create detailed and attractive job descriptions.",
    "prompts": [
      "Write a job description for a software engineer.",
      "What should I include in a description for a marketing manager role?",
      "Generate a job description template for a customer service position."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Key Performance Indicators (KPI) Dashboard",
    "label": "Key Performance Indicators (KPI) Dashboard",
    "image": "",
    "description": "Design dashboards to track key performance metrics.",
    "prompts": [
      "Create a KPI dashboard for tracking sales performance.",
      "What are the essential KPIs for measuring customer satisfaction?",
      "Generate a template for a project management KPI dashboard."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "L&D",
    "label": "L&D",
    "image": "",
    "description": "Support learning and development initiatives.",
    "prompts": [
      "Plan a training program for leadership development.",
      "How do I measure the impact of an L&D program?",
      "Generate ideas for employee skill-building workshops."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "lead-analysis",
    "label": "Lead Analysis",
    "image": "",
    "description": "Analyse and generate insights for lead text.",
    "prompts": [
      "Analyse this lead text for clarity and impact.",
      "What are the strengths of this lead text?",
      "Suggest improvements for this article's lead."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "Lead Generation",
    "label": "Lead Generation",
    "image": "",
    "description": "Identify and analyse potential leads to grow your business.",
    "prompts": [
      "Generate a list of leads for our new product.",
      "How do I qualify leads for my sales pipeline?",
      "What are the best strategies for lead generation?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "leadText",
    "label": "Lead Text",
    "image": "",
    "description": "Generate impactful lead text for articles.",
    "prompts": [
      "Write a lead text to summarise this article.",
      "Generate an engaging opening sentence for this story.",
      "What’s a strong lead text to captivate readers?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "Leadership Communication",
    "label": "Leadership Communication",
    "image": "",
    "description": "Craft effective communication for leadership teams.",
    "prompts": [
      "Write a leadership message for the start of the new fiscal year.",
      "How do I communicate a major organisational change to staff?",
      "Generate talking points for a leadership town hall meeting."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Legal Chatbot",
    "label": "Legal Chatbot",
    "image": "",
    "description": "Provide automated legal advice and assistance.",
    "prompts": [
      "How do I create a chatbot for legal FAQs?",
      "Generate a chatbot script for employment law queries.",
      "What are the common questions for a GDPR chatbot?"
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Legal Document Drafting",
    "label": "Legal Document Drafting",
    "image": "",
    "description": "Draft legal documents like contracts and agreements.",
    "prompts": [
      "Draft a non-disclosure agreement (NDA).",
      "Create a legal contract for freelance work.",
      "How do I structure a rental agreement?"
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Legal Research Assistant",
    "label": "Legal Research Assistant",
    "image": "",
    "description": "Assist with legal research and document preparation.",
    "prompts": [
      "Research case laws on data privacy.",
      "What are the legal implications of AI in healthcare?",
      "Generate a summary of GDPR regulations."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Long-Form Article Composer",
    "label": "Long-Form Article Composer",
    "image": "",
    "description": "Generate in-depth, long-form articles with detailed content.",
    "prompts": [
      "Write a detailed article on renewable energy.",
      "Generate a comprehensive guide on remote work.",
      "Create a long-form article on the future of AI."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Market Research",
    "label": "Market Research",
    "image": "",
    "description": "Conduct market research to understand trends and audiences.",
    "prompts": [
      "Conduct market research for a new product launch.",
      "What are the current trends in digital marketing?",
      "How can I identify my target audience?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Market Trends Analysis",
    "label": "Market Trends Analysis",
    "image": "",
    "description": "Identify and analyse key market trends.",
    "prompts": [
      "Analyse trends in the renewable energy sector.",
      "What are the emerging market opportunities for 2024?",
      "Generate insights on consumer behavior trends."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Marketing Analytics and Reporting",
    "label": "Marketing Analytics and Reporting",
    "image": "",
    "description": "Analyse marketing performance and generate detailed reports.",
    "prompts": [
      "How do I track the success of my campaign?",
      "Generate a marketing report for Q1.",
      "What KPIs should I include in my report?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Media Planning",
    "label": "Media Planning",
    "image": "",
    "description": "Plan and optimise media placements for your campaigns.",
    "prompts": [
      "Create a media plan for a digital ad campaign.",
      "What should I consider when planning ad placements?",
      "How do I allocate my budget for media planning?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Mergers and Acquisitions Analysis",
    "label": "Mergers and Acquisitions Analysis",
    "image": "",
    "description": "Analyse and evaluate mergers and acquisitions opportunities.",
    "prompts": [
      "Analyse the potential benefits of this acquisition.",
      "What are the risks involved in this merger proposal?",
      "Generate a financial evaluation of a merger opportunity."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "metaDescription",
    "label": "Meta Description",
    "image": "",
    "description": "Create SEO-friendly meta descriptions for your content.",
    "prompts": [
      "Generate an SEO-optimised meta description.",
      "What’s a good meta description for this article?",
      "Write a concise and effective meta description."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "mirrorpix-caption",
    "label": "Mirrorpix Caption",
    "image": "",
    "description": "Write compelling captions for Mirrorpix images.",
    "prompts": [
      "Create a caption for this Mirrorpix image.",
      "What’s a good caption to describe this photo?",
      "Generate an attention-grabbing caption for this content."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "mirrorpix-category",
    "label": "Mirrorpix Category",
    "image": "",
    "description": "Categorise Mirrorpix images effectively.",
    "prompts": [
      "Assign a category to this Mirrorpix image.",
      "What’s the best category for this photo?",
      "Generate categories for organising this content."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "mirrorpix-infer-location",
    "label": "Mirrorpix Infer Location",
    "image": "",
    "description": "Infer the location depicted in Mirrorpix images.",
    "prompts": [
      "Where is this Mirrorpix image likely taken?",
      "Analyse the location in this photo.",
      "Infer the geographical setting of this Mirrorpix content."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "mirrorpix-tags",
    "label": "Mirrorpix Tags",
    "image": "",
    "description": "Generate tags for categorising Mirrorpix images.",
    "prompts": [
      "Suggest tags for this Mirrorpix image.",
      "What keywords are relevant to this photo?",
      "Generate a set of tags for this Mirrorpix content."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "mirrorpix-title",
    "label": "Mirrorpix Title",
    "image": "",
    "description": "Generate descriptive titles for Mirrorpix images.",
    "prompts": [
      "Write a title for this Mirrorpix image.",
      "What’s a descriptive title for this photo?",
      "Generate an engaging title for this Mirrorpix content."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "Network Security Management",
    "label": "Network Security Management",
    "image": "",
    "description": "Manage and secure networks to prevent unauthorised access.",
    "prompts": [
      "How do I secure a wireless network from external threats?",
      "Generate a checklist for network security compliance.",
      "What are the best practices for managing firewall configurations?"
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "News Wire to Article",
    "label": "News Wire to Article",
    "image": "",
    "description": "Transform news wire content into polished articles.",
    "prompts": [
      "Write an article based on this news wire.",
      "How do I adapt news wire content for publication?",
      "Generate an article summarising this press release."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "newsLettersAndReferrersHeadline",
    "label": "Newsletters And Referrers Headline",
    "image": "",
    "description": "Craft compelling headlines for newsletters and referrers.",
    "prompts": [
      "Write a headline for our newsletter audience.",
      "Generate a referrer-friendly headline for this article.",
      "What’s a good headline to attract newsletter clicks?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "Patent Search",
    "label": "Patent Search",
    "image": "",
    "description": "Search for existing patents and evaluate their relevance.",
    "prompts": [
      "Search for patents related to blockchain technology.",
      "How do I ensure my product doesn't infringe existing patents?",
      "Generate a list of recent patents in the AI domain."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Post Campaign Report",
    "label": "Post Campaign Report",
    "image": "",
    "description": "Generate detailed reports after your campaigns.",
    "prompts": [
      "Generate a report on our latest ad campaign.",
      "What metrics should I include in a post-campaign analysis?",
      "Summarise the performance of the recent social media campaign."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Predictive Analytics",
    "label": "Predictive Analytics",
    "image": "",
    "description": "Use data to predict future trends and outcomes.",
    "prompts": [
      "Predict sales trends for the next quarter.",
      "What are the key indicators for customer retention?",
      "Generate a forecast for website traffic based on historical data."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Press Release to Article",
    "label": "Press Release to Article",
    "image": "",
    "description": "Convert press releases into compelling articles.",
    "prompts": [
      "Write an article from this press release.",
      "How do I make a press release more engaging?",
      "Generate a news article based on this press release."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Product Concept Development",
    "label": "Product Concept Development",
    "image": "",
    "description": "Develop concepts for new software products.",
    "prompts": [
      "Create a concept for a new educational app.",
      "How do I outline a concept for a B2B SaaS product?",
      "Generate a roadmap for developing a new product concept."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Product Generator",
    "label": "Product Generator",
    "image": "",
    "description": "Generate product ideas and descriptions.",
    "prompts": [
      "Generate a product idea for a sustainable kitchen gadget.",
      "Write a product description for an AI-powered vacuum cleaner.",
      "How do I create a unique selling point for this product?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Product Launch Plan",
    "label": "Product Launch Plan",
    "image": "",
    "description": "Create a comprehensive plan for launching a new product.",
    "prompts": [
      "Help me plan a product launch for Q3.",
      "What should I include in a product launch plan?",
      "Generate a timeline for a product launch campaign."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Product Pricing Strategy",
    "label": "Product Pricing Strategy",
    "image": "",
    "description": "Develop an effective pricing strategy for your product.",
    "prompts": [
      "Suggest a pricing strategy for a SaaS product.",
      "What factors should I consider when pricing a new product?",
      "Generate a competitive pricing model for a tech startup."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Product Requirements Documentation",
    "label": "Product Requirements Documentation",
    "image": "",
    "description": "Document detailed requirements for your product.",
    "prompts": [
      "Draft a requirements document for a mobile app.",
      "What should I include in a product requirements document?",
      "Generate a template for documenting product features."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Product Review",
    "label": "Product Review",
    "image": "",
    "description": "Write detailed and engaging product reviews.",
    "prompts": [
      "Write a review for the latest smartphone.",
      "How can I structure a product review for better engagement?",
      "Generate a product review for this gadget."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Product Roadmap",
    "label": "Product Roadmap",
    "image": "",
    "description": "Plan and visualise your product roadmap effectively.",
    "prompts": [
      "Help me create a product roadmap for the next quarter.",
      "What should I include in a roadmap for a tech product?",
      "Generate a timeline for a product launch plan."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Prompt Creator",
    "label": "Prompt Creator",
    "image": "",
    "description": "Generate effective prompts for various AI applications.",
    "prompts": [
      "Create a prompt for generating marketing copy.",
      "How do I write an effective prompt for image generation?",
      "Generate a prompt for summarising articles."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Prompt Improver",
    "label": "Prompt Improver",
    "image": "",
    "description": "Refine and improve existing prompts for better results.",
    "prompts": [
      "Improve this prompt for better AI-generated responses.",
      "How can I rewrite this prompt to be more specific?",
      "Optimise this prompt for generating SEO content."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Prototyping and Testing",
    "label": "Prototyping and Testing",
    "image": "",
    "description": "Create and test prototypes for software or hardware projects.",
    "prompts": [
      "How do I create a prototype for a mobile app?",
      "Generate a testing plan for this software prototype.",
      "What tools can I use for rapid prototyping?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Public Notices Article Generator",
    "label": "Public Notices Article Generator",
    "image": "",
    "description": "Create clear and concise public notice articles.",
    "prompts": [
      "Write a public notice for a community event.",
      "How do I structure a public notice article?",
      "Generate a notice for a government policy update."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Public Notices Article Summary",
    "label": "Public Notices Article Summary",
    "image": "",
    "description": "Summarise public notices into concise articles.",
    "prompts": [
      "Summarise this public notice about road closures.",
      "Generate a brief summary of this community event notice.",
      "How can I condense this public notice for publication?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Recipe Creator",
    "label": "Recipe Creator",
    "image": "",
    "description": "Create and write recipes with clear instructions.",
    "prompts": [
      "Write a recipe for a chocolate cake.",
      "How do I create a step-by-step recipe guide?",
      "Generate a recipe for a healthy smoothie."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Requirements Gathering",
    "label": "Requirements Gathering",
    "image": "",
    "description": "Document and analyse requirements for software projects.",
    "prompts": [
      "Help me gather requirements for a new mobile app.",
      "What should I include in a requirements document?",
      "Generate a checklist for software requirements gathering."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Research Assistant",
    "label": "Research Assistant",
    "image": "",
    "description": "Assist with research on software and technology topics.",
    "prompts": [
      "Research the latest trends in machine learning.",
      "How do I structure research notes for an article?",
      "Generate a report on cloud computing technologies."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Restaurant Review",
    "label": "Restaurant Review",
    "image": "",
    "description": "Compose reviews for restaurants with detailed insights.",
    "prompts": [
      "Write a review for a fine-dining restaurant.",
      "How can I describe the ambiance of a restaurant?",
      "Generate a detailed review for a local café."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Review Contract",
    "label": "Review Contract",
    "image": "",
    "description": "Analyse and provide feedback on legal contracts.",
    "prompts": [
      "Review this employment contract for legal risks.",
      "What are the key issues in this vendor agreement?",
      "Generate suggestions to improve this contract's clarity."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "ROI Calculation",
    "label": "ROI Calculation",
    "image": "",
    "description": "Calculate the return on investment for campaigns or projects.",
    "prompts": [
      "Calculate the ROI for our latest ad campaign.",
      "How do I measure the ROI of a content marketing strategy?",
      "Generate a report on the ROI of our digital marketing efforts."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Sales Performance Analysis",
    "label": "Sales Performance Analysis",
    "image": "",
    "description": "Analyse sales data to track and improve performance.",
    "prompts": [
      "Analyse sales performance for the last quarter.",
      "What are the top-performing products in our catalog?",
      "Generate insights from our latest sales figures."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Sales Pitch Deck",
    "label": "Sales Pitch Deck",
    "image": "",
    "description": "Create persuasive pitch decks to secure client buy-in.",
    "prompts": [
      "Design a pitch deck for an investment proposal.",
      "What should I include in a startup sales pitch?",
      "Generate slides for a product launch pitch."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Sales Presentation",
    "label": "Sales Presentation",
    "image": "",
    "description": "Design impactful presentations to boost your sales efforts.",
    "prompts": [
      "Create a sales presentation for a new product.",
      "What should I include in a B2B sales deck?",
      "Generate a visually appealing presentation for a SaaS product."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Sales Report",
    "label": "Sales Report",
    "image": "",
    "description": "Generate detailed reports to analyse sales performance.",
    "prompts": [
      "Write a sales report for Q1.",
      "How can I summarise sales data for my team?",
      "Generate insights from the latest sales metrics."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Sentiment Analysis",
    "label": "Sentiment Analysis",
    "image": "",
    "description": "Analyse customer sentiment from feedback or reviews.",
    "prompts": [
      "Analyse the sentiment of customer reviews for our product.",
      "What is the sentiment of these social media comments?",
      "Generate insights from survey responses."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "SEO",
    "label": "SEO",
    "image": "",
    "description": "Optimise your website content for search engine visibility.",
    "prompts": [
      "How do I improve my website’s SEO?",
      "Generate keywords for an article about technology.",
      "What are the best SEO practices for 2024?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "SEO Optimised product listings",
    "label": "SEO Optimised product listings",
    "image": "",
    "description": "Create product listings optimised for search engines.",
    "prompts": [
      "Write an SEO-friendly product description for a smartphone.",
      "What keywords should I include in a product listing for better visibility?",
      "Generate an optimised title for a new fitness tracker."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Service Review",
    "label": "Service Review",
    "image": "",
    "description": "Generate reviews for various services.",
    "prompts": [
      "Write a review for a car rental service.",
      "How do I structure a service review for clarity?",
      "Generate a review for this online streaming service."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Skimlinks Affiliate Offer Article Generator",
    "label": "Skimlinks Affiliate Offer Article Generator",
    "image": "",
    "description": "Generate affiliate offer articles for Skimlinks campaigns.",
    "prompts": [
      "Write an article for a Skimlinks affiliate offer.",
      "What should I include in a Skimlinks affiliate article?",
      "Generate content for Skimlinks affiliate products."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Skimlinks Product Article Generator",
    "label": "Skimlinks Product Article Generator",
    "image": "",
    "description": "Create product articles optimised for Skimlinks campaigns.",
    "prompts": [
      "Generate a product article for Skimlinks.",
      "What makes a good product article for affiliates?",
      "Write engaging content for a Skimlinks product campaign."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "metaTitle",
    "label": "Social Headline",
    "image": "",
    "description": "Generate engaging headlines for social media platforms.",
    "prompts": [
      "Create a catchy social media headline for this article.",
      "What’s an engaging headline for this post?",
      "Generate a social headline to boost shares."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "Social Media Content Generation",
    "label": "Social Media Content Generation",
    "image": "",
    "description": "Create engaging content for your social media platforms.",
    "prompts": [
      "Write a post for Instagram promoting our sale.",
      "Generate ideas for engaging Twitter content.",
      "How do I write a viral LinkedIn post?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Social Media Trends and Sentiment Analysis",
    "label": "Social Media Trends and Sentiment Analysis",
    "image": "",
    "description": "Analyse social media conversations to uncover trends, sentiments, and actionable insights to inform brand strategies.",
    "prompts": [
      "Analyse the latest sentiment around our brand on Twitter.",
      "Track the most popular hashtags in the industry this week.",
      "Compare brand sentiment between us and our competitors on Instagram.",
      "Generate a report on trending topics and key themes from the last 24 hours of social media discussions."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Software Design and Architecture",
    "label": "Software Design and Architecture",
    "image": "",
    "description": "Design and plan the architecture for software systems.",
    "prompts": [
      "Create a software design for an e-commerce platform.",
      "What are the key principles of scalable architecture?",
      "Generate a diagram for a microservices-based system."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Software Installation and Configuration",
    "label": "Software Installation and Configuration",
    "image": "",
    "description": "Guide software installation and setup processes.",
    "prompts": [
      "How do I install and configure a database management system?",
      "Generate a guide for installing office productivity software.",
      "What steps are required to configure a new server?"
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Sponsored and Branded Content Article",
    "label": "Sponsored and Branded Content Article",
    "image": "",
    "description": "Generate sponsored and branded articles for campaigns.",
    "prompts": [
      "Create a branded content article for a new product.",
      "What should I include in a sponsored content piece?",
      "Write engaging sponsored content for a campaign."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Spreadsheets & Formula Generator",
    "label": "Spreadsheets & Formula Generator",
    "image": "",
    "description": "Create spreadsheets and formulas for HR tasks.",
    "prompts": [
      "Generate a formula to calculate leave balances in Excel.",
      "How do I create a spreadsheet to track employee attendance?",
      "Draft a payroll calculation template in Excel."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Story Ideas",
    "label": "Story Ideas",
    "image": "",
    "description": "Generate creative and engaging story ideas.",
    "prompts": [
      "What are some story ideas about urban development?",
      "Suggest a story idea for a lifestyle blog.",
      "How can I start a story about technological advancements?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Strategic Planning",
    "label": "Strategic Planning",
    "image": "",
    "description": "Plan and prioritise strategies for business growth.",
    "prompts": [
      "Help me create a strategic plan for the next fiscal year.",
      "What should I include in a strategic planning document?",
      "Generate a SWOT analysis for a strategic planning session."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Summarise This TL;DR",
    "label": "Summarise This TL;DR",
    "image": "",
    "description": "Generate quick summaries for lengthy content.",
    "prompts": [
      "Summarise this article in a few sentences.",
      "Write a TL;DR for this research paper.",
      "How do I create concise summaries for content?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "System Monitoring and Maintenance",
    "label": "System Monitoring and Maintenance",
    "image": "",
    "description": "Monitor and maintain systems for optimal performance.",
    "prompts": [
      "How do I monitor server performance in real-time?",
      "Generate a system maintenance checklist.",
      "What tools can I use for monitoring network activity?"
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Talent Acquisition",
    "label": "Talent Acquisition",
    "image": "",
    "description": "Streamline the process of acquiring top talent.",
    "prompts": [
      "Plan a strategy to attract top talent in the tech industry.",
      "How do I optimise the hiring process for better efficiency?",
      "Generate a checklist for onboarding new hires."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Target Audience Analysis",
    "label": "Target Audience Analysis",
    "image": "",
    "description": "Analyse and understand your target audience in detail.",
    "prompts": [
      "Analyse the target audience for a fitness product.",
      "How do I identify key demographics for my campaign?",
      "Generate a detailed audience analysis report."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Technical Documentation",
    "label": "Technical Documentation",
    "image": "",
    "description": "Write clear and concise documentation for software projects.",
    "prompts": [
      "Draft API documentation for a RESTful service.",
      "How do I create a user guide for my app?",
      "Generate a template for project documentation."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Technology Scouting",
    "label": "Technology Scouting",
    "image": "",
    "description": "Discover and evaluate new technologies for adoption.",
    "prompts": [
      "Research emerging technologies in the healthcare industry.",
      "What tools are trending for cloud-based application development?",
      "Generate a list of innovative technologies for our business."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Technology Training and Documentation",
    "label": "Technology Training and Documentation",
    "image": "",
    "description": "Provide training and documentation for technical tools.",
    "prompts": [
      "How do I create a training program for new software?",
      "Generate a user manual for our internal tools.",
      "What should I include in a technical training session?"
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Telling a HR Data Story",
    "label": "Telling a HR Data Story",
    "image": "",
    "description": "Visualise and communicate HR data effectively.",
    "prompts": [
      "Create a data story about employee engagement trends.",
      "How do I present HR data to the leadership team?",
      "Generate insights from employee turnover statistics."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Text and Word Frequency Analysis",
    "label": "Text and Word Frequency Analysis",
    "image": "",
    "description": "Analyse the frequency of words and phrases in text data.",
    "prompts": [
      "Analyse the most common words in this text.",
      "How do I identify frequently used terms in this document?",
      "Generate a word frequency analysis for this report."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Text-to-Postcode Analyser",
    "label": "Text-to-Postcode Analyser",
    "image": "",
    "description": "Analyse and extract postcode information from text.",
    "prompts": [
      "Extract postcodes from this list of addresses.",
      "How do I identify postcodes in this document?",
      "Generate a report of unique postcodes from the given data."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Title Suggestions",
    "label": "Title Suggestions",
    "image": "",
    "description": "Generate creative and effective title suggestions.",
    "prompts": [
      "Suggest a title for my article on space exploration.",
      "What’s a catchy title for a tech blog post?",
      "Generate a list of titles for a marketing article."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Travel Review",
    "label": "Travel Review",
    "image": "",
    "description": "Write engaging reviews of travel destinations.",
    "prompts": [
      "Write a travel review for Paris.",
      "How can I describe my recent trip to Japan?",
      "Generate a review for a beach resort in the Maldives."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Troubleshooting Assistance",
    "label": "Troubleshooting Assistance",
    "image": "",
    "description": "Assist with diagnosing and resolving technical issues.",
    "prompts": [
      "Help me troubleshoot a network connectivity issue.",
      "What are common fixes for application crashes?",
      "Generate a checklist for diagnosing server issues."
    ],
    "enabled": false,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "User Feedback Analysis",
    "label": "User Feedback Analysis",
    "image": "",
    "description": "Analyse user feedback to gain actionable insights.",
    "prompts": [
      "Summarise feedback from user surveys.",
      "What are the top pain points users mentioned?",
      "Generate insights from customer feedback."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "User Persona Creation",
    "label": "User Persona Creation",
    "image": "",
    "description": "Develop detailed user personas for product design.",
    "prompts": [
      "Create a user persona for a fitness app.",
      "What should I include in a persona for a travel website?",
      "Generate a persona for a tech-savvy audience."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "User Journey Mapping and Analysis",
    "label": "User Journey Mapping and Analysis",
    "image": "",
    "description": "Track and analyse user interactions across different touchpoints to provide actionable insights for improving customer experience.",
    "prompts": [
      "Map the user journey for a website visitor from awareness to purchase.",
      "Identify pain points in the customer journey of an e-commerce site.",
      "Create a visual user journey for a mobile app."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Version Control",
    "label": "Version Control",
    "image": "",
    "description": "Manage and track changes to your codebase.",
    "prompts": [
      "How do I set up Git for my new project?",
      "Generate a guide for using branches in Git.",
      "What are the best practices for version control?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Weather Article Composer (AccuWeather)",
    "label": "Weather Article Composer (AccuWeather)",
    "image": "",
    "description": "Compose accurate weather articles using AccuWeather data.",
    "prompts": [
      "Write a weather report for London this week.",
      "Generate an article on upcoming storms.",
      "How do I write a weather article with AccuWeather data?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "Writers Assistant",
    "label": "Writers Assistant",
    "image": "",
    "description": "Assist writers with drafting, editing, and structuring content.",
    "prompts": [
      "Help me structure an article on remote work.",
      "Suggest improvements for this paragraph.",
      "How can I make this sentence more concise?"
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": false
  },
  {
    "value": "yimbly-category",
    "label": "Yimbly Category",
    "image": "",
    "description": "Assign categories to organise Yimbly content.",
    "prompts": [
      "Assign a category to this Yimbly content.",
      "What category best suits this article?",
      "Generate categories for classifying this content."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "yimbly-description",
    "label": "Yimbly Description",
    "image": "",
    "description": "Write engaging descriptions for Yimbly content.",
    "prompts": [
      "Create a description for this Yimbly article.",
      "What’s a good summary description for this content?",
      "Generate a concise Yimbly description."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "yimbly-leadText",
    "label": "Yimbly Lead Text",
    "image": "",
    "description": "Create impactful lead text for Yimbly articles.",
    "prompts": [
      "Write a strong lead text for this Yimbly article.",
      "What’s an engaging lead sentence for this content?",
      "Generate lead text to summarise this Yimbly content."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "yimbly-tags",
    "label": "Yimbly Tags",
    "image": "",
    "description": "Generate tags to categorise Yimbly content.",
    "prompts": [
      "Suggest tags for this Yimbly content.",
      "What are relevant keywords for tagging this content?",
      "Generate tags to enhance discoverability for this article."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  },
  {
    "value": "yimbly-title",
    "label": "Yimbly Title",
    "image": "",
    "description": "Generate compelling titles for Yimbly content.",
    "prompts": [
      "Write a title for this Yimbly article.",
      "What’s a strong title for this Yimbly content?",
      "Generate an attention-grabbing Yimbly title."
    ],
    "enabled": true,
    "includeInChat": true,
    "includeInBatch": true
  }
]
