import React from "react";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const updateLocalStorage = (key, updates) => {
  const existingSettings = JSON.parse(localStorage.getItem(key) || "{}");
  const updatedSettings = {
    ...existingSettings,
    ...updates,
  };
  localStorage.setItem(key, JSON.stringify(updatedSettings));
};

const ServiceSelector = ({ currentService, services, onServiceChange, serviceMode = "chat" }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const localStorageKey = `scribe-${serviceMode}-options`;

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuItemClick = (service) => {
    onServiceChange(service);
    updateLocalStorage(localStorageKey, {
      settings: {
        serviceType: service,
      },
    });
    setAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: "#3c3f44",
        borderRadius: 1,
        padding: "6px 12px",
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={handleClick}
    >
      <Typography variant="body2" color="white" sx={{ marginRight: 1 }}>
        Service: {services.find((s) => s.value === currentService)?.label || "Select Service"}
      </Typography>
      <ArrowDropDownIcon sx={{ color: "white" }} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#3c3f44",
            color: "white",
            borderRadius: 1,
          },
        }}
      >
        {services.map((service) => (
          <MenuItem
            key={service.value}
            onClick={() => handleMenuItemClick(service.value)}
            sx={{
              "&:hover": { backgroundColor: "#565a5e" },
            }}
          >
            {service.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ServiceSelector;
