import Papa from 'papaparse';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Define model configurations within the function
const modelConfigurations = {
  'gpt-3.5-turbo': {
    tokenLimitPerMinute: 160000,
    rateLimitPerMinute: 5000,
    processingTimePerToken: 7, // 6.5ms per token for GPT-3.5
    inputCostPer1KTokens: 0.0005,
    outputCostPer1KTokens: 0.0015,
    contextWindow: 128000
  },
  'gpt-4': {
    tokenLimitPerMinute: 80000,
    rateLimitPerMinute: 5000,
    processingTimePerToken: 94, // 94ms per token for GPT-4
    inputCostPer1KTokens: 0.03,
    outputCostPer1KTokens: 0.06,
    contextWindow: 128000
  },
  'gpt-4-turbo': {
    tokenLimitPerMinute: 600000,
    rateLimitPerMinute: 5000,
    processingTimePerToken: 19, // 18.2ms per token for GPT-4
    inputCostPer1KTokens: 0.01,
    outputCostPer1KTokens: 0.03,
    contextWindow: 128000
  },
  'gpt-4-turbo-preview': {
    tokenLimitPerMinute: 600000,
    rateLimitPerMinute: 5000,
    processingTimePerToken: 19, // 18.2ms per token for GPT-4
    inputCostPer1KTokens: 0.01,
    outputCostPer1KTokens: 0.03,
    contextWindow: 128000
  },
  'gpt-4o': {
    tokenLimitPerMinute: 600000,
    rateLimitPerMinute: 5000,
    processingTimePerToken: 16, // 15.0ms per token for GPT-4
    inputCostPer1KTokens: 0.005,
    outputCostPer1KTokens: 0.015,
    contextWindow: 128000
  },
  'gpt-4o-mini': {
    tokenLimitPerMinute: 600000,
    rateLimitPerMinute: 5000,
    processingTimePerToken: 16, // 15.0ms per token for GPT-4
    inputCostPer1KTokens: 0.005,
    outputCostPer1KTokens: 0.015,
    contextWindow: 128000
  },
  'unknown-model': {
    tokenLimitPerMinute: 500000,
    rateLimitPerMinute: 5000,
    processingTimePerToken: 25, // Example value
    inputCostPer1KTokens: 0.005,
    outputCostPer1KTokens: 0.015,
    contextWindow: 128000, // Assuming same context window
  },
};

export const validateFile = (file) => {
  if (!file) {
    return { valid: false, error: 'No file selected.' };
  }

  if (!file.name.endsWith('.csv')) {
    return { valid: false, error: 'Please upload a CSV file.' };
  }

  if (file.size > 5 * 1024 * 1024) {
    // 5MB limit
    return { valid: false, error: 'File size should not exceed 5MB.' };
  }

  return { valid: true, error: '' };
};

export const detectSeparator = (
  file,
  primaryCallback,
  additionalCallback = null
) => {
  const reader = new FileReader();
  reader.onload = function (e) {
    const text = e.target.result;
    Papa.parse(text, {
      preview: 10, // Read only first 10 lines for detection
      complete: function (results) {
        const detectedSeparator = results.meta.delimiter;
        primaryCallback(detectedSeparator);
        if (additionalCallback) {
          additionalCallback(file, detectedSeparator);
        }
      },
    });
  };
  reader.readAsText(file.slice(0, 5000)); // Read first 5000 bytes of the file
};

export const parseCsvFile = (file, separator, header, callback) => {
  Papa.parse(file, {
    delimiter: separator,
    header: header,
    complete: callback,
  });
};

const escapeCsvValue = (val) => {
  if (val === null || val === undefined) {
    return '';
  }
  let value = String(val);
  // Escape double quotes by doubling them
  value = value.replace(/"/g, '""');
  // If value contains a comma, newline or double-quote, enclose in double quotes
  if (value.search(/("|,|\n)/g) >= 0) {
    value = `"${value}"`;
  }
  return value;
};

export const convertArrayToCSV = (array) => {
  if (array.length === 0) {
    return '';
  }

  const headers = Object.keys(array[0]);
  const csvRows = array.map((row) =>
    headers.map((fieldName) => escapeCsvValue(row[fieldName])).join(',')
  );

  return [headers.join(','), ...csvRows].join('\r\n');
};

export const createChunks = (data, separator, maxSize) => {
  let chunks = [];
  let currentChunk = ''; // Holds the current chunk as a string

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    // Determine if adding this item and separator would exceed maxSize
    const addition = currentChunk.length > 0 ? separator + item : item; // Add separator if not the first item in chunk
    if (currentChunk.length + addition.length <= maxSize) {
      currentChunk += addition;
    } else {
      // If adding the current item would exceed maxSize, push the current chunk (if not empty) and start a new one
      if (currentChunk !== '') chunks.push(currentChunk);
      currentChunk = item;
    }
  }

  // Add the last chunk if it exists
  if (
    currentChunk !== '' ||
    (data.length > 0 && data[data.length - 1] === '')
  ) {
    chunks.push(currentChunk);
  }

  // Adjust the last chunk: remove the trailing separator if the last data item is not an empty string
  if (data.length > 0 && data[data.length - 1] !== '') {
    const lastChunkIndex = chunks.length - 1;
    chunks[lastChunkIndex] = chunks[lastChunkIndex].endsWith(separator)
      ? chunks[lastChunkIndex].slice(0, -separator.length)
      : chunks[lastChunkIndex];
  }

  return chunks;
};

export const countAndSplitInput = (input, separator) => {
  try {
    let result = { count: 0, contents: [] };

    if (Array.isArray(input)) {
      result.count = input.length;
      result.contents = input;
    } else if (typeof input === 'string') {
      const contents = input.split(separator);

      // Check if the string ends with the separator
      // If it does, the split method will remove the last empty string
      // To account for this as an item, we check if the input ends with the separator
      if (input.endsWith(separator)) {
        // Add an empty string to represent the trailing item
        contents.push('');
      }

      result.count = contents.length;
      result.contents = contents;
    } else {
      throw new Error('Input must be either a string or an array.');
    }

    return result;
  } catch (error) {
    return { error: error.message, count: 0, contents: [] };
  }
};

export const formatDuration = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''}`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else {
    return `${seconds} second${seconds !== 1 ? 's' : ''}`;
  }
};

// Function to get model configuration with fallback
const getModelConfig = (model) => {
  return modelConfigurations[model] || modelConfigurations.default;
};


export const estimateProcessingTime = (characterCount, model) => {
  // Ensure characterCount is a valid number
  const parsedCharacterCount = parseInt(characterCount);

  // Check if the conversion is successful and characterCount is a number
  if (isNaN(parsedCharacterCount)) {
    // Return an error message if characterCount is not a number
    return 'Character count must be a valid number.';
  }

  // Get model configuration
  const modelConfig = getModelConfig(model);

  // Extract processing time per token from model configuration
  const processingTimePerToken = modelConfig.processingTimePerToken || 50;

  // Base assumption for characters per token
  const baseCharactersPerToken = 3;

  // Additional adjustment factors
  const punctuationAdjustmentFactor = 0.3; // Assuming punctuation might slightly increase token count
  const spaceAdjustmentFactor = 1; // Assuming spaces are good indicators of word boundaries

  // Counting spaces and punctuation in the character count to adjust the estimation
  // For simplicity, this example assumes you have a way to count these or adjust based on average expectations
  const estimatedSpaces = parsedCharacterCount / 5; // Example: 1 space per 5 characters as a rough estimate
  const estimatedPunctuation = parsedCharacterCount / 20; // Example: 1 punctuation per 20 characters as a rough estimate

  // Adjusting the base token count with the assumption that spaces and punctuation might change the average ratio
  const adjustedTokenCount = Math.ceil(
    (parsedCharacterCount +
      estimatedSpaces * spaceAdjustmentFactor +
      estimatedPunctuation * punctuationAdjustmentFactor) /
    baseCharactersPerToken
  );

  // Calculate the estimated processing time in milliseconds
  let estimatedTimeMs = adjustedTokenCount * processingTimePerToken;

  // Ensure estimated time is not less than 20 seconds (20000 milliseconds)
  estimatedTimeMs = Math.max(estimatedTimeMs, 20000);

  // Adjust estimated time based on rate limit and token limit per minute
  const rateLimit = modelConfig.rateLimitPerMinute;
  const tokenLimit = modelConfig.tokenLimitPerMinute;

  const tokenTimeMs = 60000 / tokenLimit; // Time for one token in milliseconds
  const maxTokenUsage = tokenLimit / rateLimit; // Maximum tokens consumed per millisecond

  if (adjustedTokenCount > maxTokenUsage) {
    const timeToWait = (adjustedTokenCount / maxTokenUsage) * tokenTimeMs;
    estimatedTimeMs += timeToWait;
  }

  // Format the estimated time in a human-readable format
  const formattedTime = formatDuration(estimatedTimeMs);

  return estimatedTimeMs;
};

// Helper function to calculate estimated processing time
export const calculateEstimatedProcessingTime = (data, selectedColumn, selectedSecondColumn, selectedThirdColumn, modelValue) => {
  // Calculate total character count considering both selected and combined columns
  const characterCount = data.reduce((total, row) => {
    let rowCharacterCount = 0;
    // Check and add character count from the primary selected column if defined
    if (selectedColumn && row[selectedColumn] !== undefined && row[selectedColumn] !== null) {
      rowCharacterCount += row[selectedColumn].toString().length;
    }
    // Check and add character count from the additional second Column if defined
    if (selectedSecondColumn && row[selectedSecondColumn] !== undefined && row[selectedSecondColumn] !== null) {
      rowCharacterCount += row[selectedSecondColumn].toString().length;
    }
    // Check and add character count from the additional third Column if defined
    if (selectedThirdColumn && row[selectedThirdColumn] !== undefined && row[selectedThirdColumn] !== null) {
      rowCharacterCount += row[selectedThirdColumn].toString().length;
    }
    return total + rowCharacterCount;
  }, 0);

  return estimateProcessingTime(characterCount, modelValue);
};

export const prepareDataWithCombineColumn = (data, fields) => {
  return data.map((row) => fields.map((field) => row[field] || '').join(' '));
};
export const ColumnField = (props) => {
  const { selectColumn, handleColumn, columnNumber, columns } = props;
  return (
    <div>
      <Tooltip title='Select a column from the CSV file'>
        <Autocomplete
          multiple
          size='small'
          value={selectColumn}
          onChange={handleColumn}
          options={columns}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField {...params} label={`Select data-fields for ${columnNumber} aggregated column`} />}
          sx={{ mb: 2 }}
        />
      </Tooltip>
    </div>
  );
};
export const customResponse = (title, items) => {
  if (items.length === 0) {
    return;
  }

  return (
    <div>
      <Card>
        <CardContent>
          <Typography variant='h6' gutterBottom>
            {`${title} (first 10 items):`}
          </Typography>
          <Box sx={{ minHeight: 250, maxHeight: 'auto', overflow: 'auto' }}>
            {items.map((value, index) => (
              <Typography key={index} variant='body2'>
                {value}
              </Typography>
            ))}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export const ColumnPreview = (props) => {
  const { columnPreview, columnNumber } = props;

  return customResponse(
    `${columnNumber} Column Preview`,
    columnPreview,
  )
}

export const ApiResponse = (props) => {
  const { apiPreview, columnNumber } = props;

  return customResponse(
    `${columnNumber} Column API Response`,
    apiPreview,
  )
}
