import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Select, MenuItem, FormControl, InputLabel, Box, Typography, Tooltip, Container, Grid, Button, Breadcrumbs, Link
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AdvancedOptions from '../reusableComponent/AdvancedOptions';
import Texteditor from '../template/textEditor';
import { EditorState, convertToRaw } from "draft-js";
import { stateFromMarkdown } from 'draft-js-import-markdown';
import PublicNoticesPortalOptions from './PublicNotices';
import PushToCMSOptions from './PushToCMS';
import { userProfileContext } from "../../lib/UserProvider";

// API Modules
import completionsApi from '../../server/completionsApi';
import proxyApi from '../../server/proxy';

// Third-Party Libraries
import toast from 'react-hot-toast';

// Local Definitions from 'definitions' file
import {
  languages,            // Predefined set of language options
  lengths,              // Predefined set of length units
  temperatures,         // Predefined set of temperature units
  tones,                // Predefined set of tones
  serviceTypes,         // Predefined set of service types
  models,               // Predefined set of AI models
  publications,         // Predefined set of publication types
  empty,                 // Definition for an 'empty' state or selection
  booleans,
  articleTypes,
  publicationStatusOptions,
  printPublications,
  getServiceTypes
} from "../../definitions";

import {
  constructNoticeUrls,
  enqueueMessage,
  crawlNotices,
  formatNoticeData,
  filterByPublication,
  filterByDate,
  validatePostcode
} from './Helpers';

import {
  extractMetadata,
  cleanContent
} from "../reusableComponent/helpers.js";

const FeedSelectorComponent = () => {
  const navigate = useNavigate();
  const userProfile = useContext(userProfileContext);
  const [selectedFeed, setSelectedFeed] = useState('Public Notices');
  
  const publicNoticesArticleGenerator = getServiceTypes(serviceTypes, {
    specificServiceType: "Public Notices Article Generator"
  })[0]?.value;

  const publicNoticesArticleSummary = getServiceTypes(serviceTypes, {
    specificServiceType: "Public Notices Article Summary"
  })[0]?.value;

  const [input, setInput] = useState({
    useQueue: booleans[0].value,
    additionalData: {
      category: "all",
      dateRange: "all",
      orderBy: "distance",
      publications: ['all'],
      search: "",
    },
    articleData: {
      additionalContent: "",
      body: "",
      featureImageURL: "",
      leadText: "",
      metaDescription: "",
      newsLettersAndReferrersHeadline: "",
      metaTitle: "",
      tags: "",
      title: "",
    },
    cmsData: {
      cms: "",
      cmsConfig: {},
      cmsContentType: articleTypes[15].value,
      cmsEntries: [],
      cmsPublicationStatus: "draft",
    },
    options: {
      max_length: lengths[3].value,
      messages: [],
      model: models[0].value,
      temperature: temperatures[5].value,
      user: userProfile?.user_id || null,
    },
    settings: {
      articleTone: tones[5].value,
      language: languages[0].value,
      serviceType: publicNoticesArticleGenerator,
      writingStyle: empty.value
    },
    user_id: userProfile?.user_id || null,
  });
  const [feedResults, setFeedResults] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    console.log('additionalData', input.additionalData)
    if ((input.additionalData.search.trim() !== '')) {
      fetchFeedData();
    }
  }, [input.additionalData]);

  useEffect(() => {
    if (input.cmsData.cms === 'inyourarea') {
      setInput(prevInput => ({
        ...prevInput,
        settings: {
          ...prevInput.settings,
          articleTone: empty.value,
          writingStyle: selectedFeed === 'Public Notices' ? publications[37].value : publications[36].value
        },
      }));
    } else if (input.cmsData.cms.trim() !== '') {
      const foundPublication = publications.find(pub => pub.value === input.cmsData.cms);
      if (foundPublication) {
        setInput(prevInput => ({
          ...prevInput,
          settings: {
            ...prevInput.settings,
            articleTone: empty.value,
            writingStyle: foundPublication.value
          },
        }));
      }
    }
  }, [input.additionalData, input.cmsData, selectedFeed]);

  useEffect(() => {
    if (selectedItem.length === 1) {
      setInput(prevInput => ({
        ...prevInput,
        settings: {
          ...prevInput.settings,
          serviceType: publicNoticesArticleGenerator,
        },
      }));
    } else if (selectedItem.length > 1) {
      setInput(prevInput => ({
        ...prevInput,
        settings: {
          ...prevInput.settings,
          serviceType: publicNoticesArticleSummary,
        },
      }));
    }
  }, [selectedItem]);

  const handleFeedChange = (event) => {
    setSelectedFeed(event.target.value);
    clearFormData();
  };

  const onScheduleClick = () => {
    // Remove 'all' from additionalData.publications if present
    const updatedPublications = input.additionalData.publications.filter(pub => pub !== 'all');

    // Create a new input object with the updated publications array
    const updatedInput = {
      ...input,
      additionalData: {
        ...input.additionalData,
        publications: updatedPublications
      }
    };

    navigate("/create-task", { state: { input: updatedInput, type: selectedFeed } });
  };

  const clearFormData = (clearSelectedFeed = true) => {
    setInput({
      useQueue: booleans[0].value,
      additionalData: {
        category: "all",
        dateRange: "all",
        orderBy: "distance",
        publications: ['all'],
        search: "",
      },
      articleData: {
        additionalContent: "",
        body: "",
        featureImageURL: "",
        leadText: "",
        metaDescription: "",
        newsLettersAndReferrersHeadline: "",
        metaTitle: "",
        tags: "",
        title: "",
      },
      cmsData: {
        cms: "",
        cmsConfig: {},
        cmsContentType: articleTypes[15].value,
        cmsEntries: [],
        cmsPublicationStatus: "draft",
      },
      options: {
        max_length: lengths[1].value,
        messages: [],
        model: models[0].value,
        temperature: temperatures[5].value,
        user: userProfile?.user_id || null,
      },
      settings: {
        articleTone: tones[5].value,
        language: languages[0].value,
        serviceType: publicNoticesArticleGenerator,
        writingStyle: empty.value
      },
      user_id: userProfile?.user_id || null,
    })
    setFeedResults([]);
    setSelectedItem([]);
    setEditorState(EditorState.createEmpty());
    setLoading(false);

    if (clearSelectedFeed) {
      setSelectedFeed('Public Notices');
    }
  }

  const fetchFeedData = async () => {
    try {
      let itemsWithUrls = [];

      if (selectedFeed === 'Public Notices') {
        // Use the fetchNoticesByPostcode function for the Public Notices Portal feed
        const { search, publications, dateRange, orderBy, category } = input.additionalData;
        const selectedType = category === "all" ? '' : category;

        if (!validatePostcode(search)) {
          toast.error("Invalid postcode. Please enter a valid postcode.");
          return;
        }

        let data = await proxyApi.fetchNoticesByPostcode(search, orderBy, selectedType);

        // Sort data by publishStartDate
        data.sort((a, b) => new Date(b.publishStartDate) - new Date(a.publishStartDate));

        // Filter data based on the publication
        data = filterByPublication(data, publications);

        // Filter data based on the start date
        data = filterByDate(data, dateRange);

        // Construct URLs or any other processing you need
        itemsWithUrls = constructNoticeUrls(data || []);
      }

      setFeedResults(itemsWithUrls);

      // Set selectedItem to the first item in itemsWithUrls, if it exists
      if (itemsWithUrls.length > 0) {
        setSelectedItem([{
          label: itemsWithUrls[0].title,
          value: itemsWithUrls[0].url,
          title: itemsWithUrls[0].title,
          url: itemsWithUrls[0].url,
          postcode: itemsWithUrls[0].postcode,
          category: itemsWithUrls[0].category
        }]);
      } else {
        setSelectedItem([{
          label: "",
          value: "",
          url: "",
          title: "",
          postcode: "",
          category: ""
        }]);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
      setFeedResults([]);
    }
  };

  const updateUIWithTaskResult = async (message, messages) => {
    const newContentState = stateFromMarkdown(message);
    const newEditorState = EditorState.createWithContent(newContentState);
    setInput(prevInput => ({
      ...prevInput,
      options: {
        ...prevInput.options,
        messages: [
          ...(prevInput.options.messages || []),
          { role: "assistant", content: message }
        ]
      },
    }));

    setSuccess(true);
    setEditorState(newEditorState);

    toast.success('Article processed successfully!', { duration: 10000 });
  }

  const processDirectOrEnqueue = async (requestBody) => {
    try {
      // Directly handle the API response or enqueue based on the useQueue flag
      const shouldEnqueue = input.useQueue || false; // Ensure boolean value

      if (!shouldEnqueue) {
        const response = await completionsApi.sendMessageToCompletionsAPI(requestBody);

        // Successful direct API call
        if (response.status === 200 && response.message && response.messages) {
          await updateUIWithTaskResult(response.message, response.messages);
          return;
        }

        // Handle specific failure scenario (e.g., timeout) or fallback to enqueue
        if (response?.response?.status === 504) {
          console.log("Timeout detected, enqueueing the message for processing.");
        } else {
          // Log specific error information here if needed before falling back to enqueue
          console.error(`API Error: ${response?.response?.data?.message || response.message}`);
        }
      }

      // Enqueue message for processing either due to initial configuration, fallback, or specific error
      await enqueueMessage(requestBody, updateUIWithTaskResult);
    } catch (error) {
      throw error;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEditorState(EditorState.createEmpty());
    setLoading(true);
    setSuccess(false);

    // Filter out non-system messages
    const filteredMessages = (input.options.messages || []).filter(msg => msg.role === "system");

    setInput(prevInput => ({
      ...prevInput,
      options: {
        ...prevInput.options,
        messages: filteredMessages
      },
    }));

    try {
      // Validation check
      if (selectedFeed !== 'Public Notices' || selectedItem.length === 0 || input.additionalData.search.length <= 5) {
        throw new Error('Invalid parameters for Public Notices Portal');
      }

      const crawledNotices = await crawlNotices(selectedItem, { publication: input.additionalData.publications[0], dateRange: input.additionalData.dateRange });
      const formattedNotices = formatNoticeData(crawledNotices);

      // Check if the service type is 'Public Notices Article Generator' and only one item is selected
      if (input.settings.serviceType === 'Public Notices Article Generator' && selectedItem.length === 1) {
        const noticeCategory = crawledNotices[0].category;
        setInput(prevInput => ({
          ...prevInput,
          additionalData: {
            ...prevInput.additionalData,
            category: noticeCategory
          }
        }));
      }

      toast.success('Website data successfully retrieved!');

      const updatedMessages = [
        ...filteredMessages,
        { role: "user", content: formattedNotices }
      ];

      setInput(prevInput => ({
        ...prevInput,
        options: {
          ...prevInput.options,
          messages: updatedMessages
        }
      }));

      const requestBody = {
        ...input,
        options: {
          ...input.options,
          messages: updatedMessages,
          tool_choice: 'none'
        }
      };

      await processDirectOrEnqueue(requestBody);
    } catch (error) {
      setSuccess(false);
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const navigateToPushToCue = (combinedInput) => {
    navigate("/push-to-cms", { state: combinedInput });
  };

  const processAndPushArticleData = async (chatContents) => {
    const metadata = extractMetadata(chatContents);
    const cleanedText = cleanContent(chatContents);

    const selectedPublication = printPublications.find(pub => pub.printTitle === input.additionalData.publications[0]);
    const updatedTags = selectedPublication ? selectedPublication.tags : '';

    const updatedArticleData = {
      ...input.articleData,
      title: cleanContent(metadata.title, true),
      leadText: cleanContent(metadata.leadText, true),
      metaTitle: cleanContent(metadata.metaTitle, true),
      metaDescription: cleanContent(metadata.metaDescription, true),
      newsLettersAndReferrersHeadline: cleanContent(metadata.newsLettersAndReferrersHeadline, true),
      body: cleanedText,
      tags: updatedTags
    };

    const categories = selectedItem.map(item => item.category);
    const allCategoriesSame = categories.every(category => category === categories[0]);

    const combinedInput = {
      ...input,
      additionalData: {
        ...input.additionalData,
        category: input.additionalData.category === "all"
          ? (allCategoriesSame ? categories[0] : "other")
          : input.additionalData.category,
      },
      articleData: updatedArticleData
    };

    navigateToPushToCue(combinedInput);
  };

  const onPushToCMSClick = async () => {
    const chatContents = input.options.messages[1].content;
    await processAndPushArticleData(chatContents);
  };

  return (
    <Container maxWidth="xl" style={{ height: "100%" }}>
      <Grid container spacing={2}>
        <Grid item sm={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={RouterLink} to="/" color="inherit">
              Home
            </Link>
            <Typography color="textPrimary">Feeds</Typography>
          </Breadcrumbs>
          <Typography variant="h4" gutterBottom>Feed Selection Tool</Typography>
          <Typography variant="subtitle1" gutterBottom>Select a feed, provide necessary parameters, and fetch data automatically.</Typography>
          {/* UI Element for Feed Selection */}
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Tooltip title="Select a feed">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="feed-select-label">Select Feed</InputLabel>
                <Select
                  size="small"
                  labelId="feed-select-label"
                  value={selectedFeed}
                  label="Select Feed"
                  onChange={handleFeedChange}
                >
                  <MenuItem value="Public Notices">Public Notices</MenuItem>
                </Select>
              </FormControl>
            </Tooltip>

            {/* UI Element for Public Notices */}
            {selectedFeed === 'Public Notices' && (
              <PublicNoticesPortalOptions
                input={input}
                setInput={setInput}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                feedResults={feedResults}
              />
            )}
            <PushToCMSOptions
              input={input}
              setInput={setInput}
            />
            <Typography sx={{ mb: 2 }}>AI Options</Typography>

            <AdvancedOptions serviceMode={"feeds"} input={input} setInput={setInput} />

            <Button
              size="small"
              variant="contained"
              color="inherit"
              onClick={clearFormData}
              sx={{ mt: 3, mb: 2, mr: 2 }}
            >
              Clear
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={onScheduleClick}
              sx={{ mt: 3, mb: 2, mr: 2 }}
            >
              Schedule Recurring
            </Button>
            <LoadingButton
              size="small"
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2, mr: 2 }}
              onClick={handleSubmit}
              loading={loading}
            >
              Run Once
            </LoadingButton>
            {success && (
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={onPushToCMSClick}
                sx={{ mt: 3, mb: 2, mr: 2 }}
              >
                Push to CMS
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* Right column content */}
          <Texteditor
            editorState={editorState}
            setEditorState={setEditorState}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default FeedSelectorComponent;
