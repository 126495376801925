import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Texteditor from "../../template/textEditor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../createArticles/aiwriter.scss";
import AiWriterSelection from "../createArticles/aiWriterSelection";
import writerApi from "../../../server/writerApi.js";
import { EditorState, convertToRaw } from "draft-js";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import insertText from "../../reusableComponent/sendTextToEditor";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import { useParams } from "react-router-dom";

const ArticleById = () => {
  const { id } = useParams();

  const initialState = () => EditorState.createEmpty();
  const [editorState, setEditorState] = useState(initialState);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [copyTextSuccessAlert, setCopyTextSuccessAlert] = useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [articleData, setArticleData] = useState([]);
  const [title, setTitle] = useState("");

  const handleClickOpenWarning = () => {
    setOpenWarning(true);
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const handleCreateTemplate = async (config) => {
    refreshTextEditor();
    setLoading(true);
    try {
      if ((config?.description?.trim() === '') && (config?.message?.trim() === '')) {
        throw new Error('Please provide a description or a message for the article.');
      }

      const article = await writerApi.sendMessageToWriterAPI(config);
      const firstLine = article?.split('\n').find(line => line.trim() !== '')?.replace(/^"|"$/g, '');
      const articleTitle = config.title || firstLine || '';
      setTitle(articleTitle);
      setText(article);
      setLoading(false);
    } catch (error) {
      setText(
        `Error: ${
          error ? error.toString() : "An error occurred. Please try again."
        }`
      );
      setLoading(false);
    }
  };
  const sendTextToEditor = (text) => {
    setEditorState(insertText(text, editorState));
  };
  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(
        convertToRaw(editorState.getCurrentContent()).blocks[0].text
      );
      setCopyTextSuccessAlert(true);
    } catch {
      return "Unable to copy text";
    }
  };
  const handleClose = () => {
    setCopyTextSuccessAlert(false);
  };
  const refreshTextEditor = () => {
    setEditorState(initialState);
  };
  const fetchArticleInfo = async () => {
    const data = await writerApi.fetchArticleById(id);
    setTitle(data[0].properties.title);
    sendTextToEditor(data[0].article_text);
  };
  useEffect(() => {
    if (id) {
      fetchArticleInfo();
      sendTextToEditor(text);
    }
  }, [text, id]);
  return (
    <div>
      <Container maxWidth="xl">
        <div className="ai-writer">
          <Grid container spacing={2}>
            <Grid item sm={5}>
              <AiWriterSelection
                id={id}
                handleClickOpenWarning={handleClickOpenWarning}
                handleCreateTemplate={handleCreateTemplate}
              />
            </Grid>
            <Grid item sm={7} className="text-editor">
              <div className="loadingcomponent">
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loading}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress size="6rem" color="inherit" />
                    <Typography position="absolute">
                      Generating<br></br>Article
                    </Typography>
                  </Box>
                </Backdrop>
              </div>
              <div>
                <Texteditor
                  editorState={editorState}
                  setEditorState={setEditorState}
                  title={title}
                  handleTitleChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};
export default ArticleById;
