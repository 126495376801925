import { services } from './services';

export const teamServiceMapping = {
  "Chat": [
    "Chat",
    "Jarvis Assistant"
  ],
  "Advertising, Marketing and Social": [
    "Ad Copy Generation",
    "Brand Messaging",
    "Campaign Brief",
    "Copywriter",
    "Digital Advertising Strategy",
    "Email Composer and Responder",
    "Market Research",
    "Marketing Analytics and Reporting",
    "Media Planning",
    "Post Campaign Report",
    "Social Media Content Generation",
    "Social Media Trends and Sentiment Analysis",
    "Target Audience Analysis",
    "User Journey Mapping and Analysis"
  ],
  "Commercial Editorial": [
    "AWIN Affiliate Offer Article Generator",
    "Skimlinks Affiliate Offer Article Generator",
    "Skimlinks Product Article Generator",
    "Amazon Affiliate Offer Article Generator",
    "Sponsored and Branded Content Article",
    "Commercial Content Editing"
  ],
  "Editorial": [
    "Article Composer",
    "Long-Form Article Composer",
    "Content and Headline Analysis",
    "Title Suggestions",
    "Story Ideas",
    "Headline Analysis and Optimisation",
    "Headline Topic and Theme Analyser",
    "Weather Article Composer (AccuWeather)",
    "Article Rewriter",
    "Article Adapter",
    "Copywriter",
    "Commenting Article Generator",
    "Public Notices Article Generator",
    "Public Notices Article Summary",
    "Football Match Report",
    "Interview or Speech to Article",
    "News Wire to Article",
    "Press Release to Article",
    "Recipe Creator",
    "Research Assistant",
    "Summarise This TL;DR",
    "Writers Assistant",
    "Product Review",
    "Service Review",
    "Restaurant Review",
    "Travel Review",
    "Editorial Calendar",
    "Content Planning",
    "Chat"
  ],
  "Legal": [
    "Legal Research Assistant",
    "Legal Document Drafting",
    "GDPR Compliance Check",
    "Consent Management",
    "Legal Chatbot",
    "Review Contract"
  ],
  "Product": [
    "Jira Ticket Creator",
    "Product Roadmap",
    "Competitor Analysis",
    "User Feedback Analysis",
    "Feature Prioritisation",
    "User Persona Creation",
    "Product Requirements Documentation",
    "Market Research",
    "Product Launch Plan",
    "Product Pricing Strategy",
    "Jarvis Assistant"
  ],
  "Commercial Sales": [
    "Client Proposal",
    "Sales Presentation",
    "Sales Report",
    "Lead Generation",
    "Sales Pitch Deck",
    "Brand Messaging",
    "Media Planning",
    "Digital Advertising Strategy",
    "Email Composer and Responder",
    "Jarvis Assistant",
    "Chat"
  ],
  "Data Analysis": [
    "SEO",
    "Data Analysis and Trends Finder",
    "Predictive Analytics",
    "Audience Segmentation Analysis",
    "Content Performance Metrics",
    "Campaign Effectiveness Analysis",
    "Sentiment Analysis",
    "Customer Behavior Insights",
    "Churn Prediction",
    "Sales Performance Analysis",
    "ROI Calculation"
  ],
  "Ecommerce": [
    "SEO Optimised product listings",
    "Product Generator"
  ],
  "HR": [
    "HR Policy Compliance",
    "HR Comms",
    "Spreadsheets & Formula Generator",
    "L&D",
    "Job Description Generator",
    "Job Advert Generator",
    "HR Meeting/Interview Questions",
    "HR FAQ Generator",
    "Telling a HR Data Story",
    "Talent Acquisition",
    "Applicant Feedback"
  ],
  "Exec and Leadership": [
    "Executive Briefing",
    "Strategic Planning",
    "Financial Analysis",
    "Board Meeting Presentation",
    "Market Trends Analysis",
    "Competitor Intelligence",
    "Key Performance Indicators (KPI) Dashboard",
    "Investor Relations Brief",
    "Leadership Communication",
    "Mergers and Acquisitions Analysis"
  ],
  "IT and Technical Support": [
    "Troubleshooting Assistance",
    "System Monitoring and Maintenance",
    "Software Installation and Configuration",
    "Network Security Management",
    "Technology Training and Documentation"
  ],
  "Software Development": [
    "Jarvis Assistant",
    "Requirements Gathering",
    "Software Design and Architecture",
    "Coding Assistance",
    "Automated Testing",
    "Code Review",
    "Bug Tracking and Resolution",
    "Version Control",
    "Deployment Automation",
    "Continuous Integration and Deployment",
    "Technical Documentation",
    "Research Assistant",
    "Patent Search",
    "Technology Scouting",
    "Prototyping and Testing",
    "Innovation Ideation",
    "Product Concept Development"
  ],
  "Cross Functional": [
    "Prompt Creator",
    "Prompt Improver",
    "Text-to-Postcode Analyser",
    "Text and Word Frequency Analysis",
    "Image Generator"
  ]
}

// Function to get enabled service names
const getEnabledServiceNames = (services) => {
  return services
    .filter((service) => service.enabled) // Filter out disabled services
    .map((service) => service.value); // Extract service names/values
};

// Function to filter and sort team mappings based on enabled services
const getFilteredTeamServiceTypeMapping = (teamMapping, enabledServices) => {
  const filteredMapping = {};

  // Sort team keys alphabetically
  const sortedTeams = Object.keys(teamMapping).sort((a, b) => a.localeCompare(b));

  sortedTeams.forEach((team) => {
    // Filter the services for each team
    const enabledTeamServices = teamMapping[team]
      .filter((service) => enabledServices.includes(service)) // Filter by enabled services
      .sort((a, b) => a.localeCompare(b)); // Sort alphabetically

    // Only add the team if there are enabled services
    if (enabledTeamServices.length > 0) {
      filteredMapping[team] = enabledTeamServices;
    }
  });

  return filteredMapping;
};

// Get enabled service names
const enabledServiceNames = getEnabledServiceNames(services);

// Generate the filtered and sorted team mapping
export const teamServiceTypeMapping = getFilteredTeamServiceTypeMapping(
  teamServiceMapping,
  enabledServiceNames
);

export const getServiceTypes = (
  services,
  {
    includeChat = false,
    includeBatch = false,
    enabledOnly = true,
    specificServiceType = null
  } = {}
) => {
  return services
    .filter((service) => (enabledOnly ? service.enabled : true)) // Filter by enabled if needed
    .filter((service) => (includeChat ? service.includeInChat : true)) // Filter by chat inclusion if needed
    .filter((service) => (includeBatch ? service.includeInBatch : true)) // Filter by batch inclusion if needed
    .filter((service) => (specificServiceType ? service.value === specificServiceType : true)) // Filter by specific serviceType if provided
    .map(({ value, label, description, image, prompts, enabled, includeInBatch, includeInChat }) => ({
      value,
      label,
      description,
      image,
      prompts,
      enabled,
      includeInBatch,
      includeInChat,
    }));
};

export const batchServiceTypes = getServiceTypes(services, {
  includeBatch: true,
  enabledOnly: true,
}).map(({ value, label }) => ({ value, label }));

export const serviceTypes = getServiceTypes(services, { includeChat: true, enabledOnly: true });