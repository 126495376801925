import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Box, Tooltip } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import LanguageIcon from '@mui/icons-material/Language';
import ImageIcon from '@mui/icons-material/Image';
import WeatherIcon from '@mui/icons-material/WbSunny';
import HandymanIcon from "@mui/icons-material/Handyman";

const tools = [
  { name: 'Google Search', icon: <GoogleIcon sx={{ marginRight: 1 }} />, value: 'googleSearch', function: { name: 'googleSearch' } },
  { name: 'Web Crawling', icon: <LanguageIcon sx={{ marginRight: 1 }} />, value: 'crawlAndExtractTextFromWebsite', function: { name: 'crawlAndExtractTextFromWebsite' } },
  { name: 'Image Generation', icon: <ImageIcon sx={{ marginRight: 1 }} />, value: 'generateImageResponse', function: { name: 'generateImageResponse' } },
  { name: 'Weather Forecast', icon: <WeatherIcon sx={{ marginRight: 1 }} />, value: 'generateWeatherForecastMessage', function: { name: 'generateWeatherForecastMessage' } }
];

const AiChatTools = ({ selectedTool, onSelectTool, mode = "light" }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToolsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToolsClose = () => {
    setAnchorEl(null);
  };

  const handleToolSelect = (tool) => {
    onSelectTool(tool);
    setAnchorEl(null);
  };

  const getSelectedIcon = () => {
    const tool = tools.find(t => t.value === selectedTool?.value);
    return tool ? tool.icon : <HandymanIcon />;
  };

  const menuStyles = {
    menu: {
      "& .MuiPaper-root": {
        backgroundColor: mode === "dark" ? "#333" : "#fff",
        color: mode === "dark" ? "#fff" : "#000",
      },
      "& .MuiMenuItem-root": {
        "&:hover": {
          backgroundColor: mode === "dark" ? "#444" : "#f0f0f0",
        },
      },
    },
  };

  return (
    <>
      <Tooltip title="Tools">
        <IconButton
          component="span"
          onClick={handleToolsClick}
        >
          {getSelectedIcon()}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleToolsClose}
        sx={menuStyles.menu}
      >
        {tools.map((tool) => (
          <MenuItem key={tool.value} onClick={() => handleToolSelect(tool)}>
            {tool.icon}
            <Box ml={1}>{tool.name}</Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AiChatTools;
