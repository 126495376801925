import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PropTypes from "prop-types";
import { convertToRaw } from "draft-js";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";

const Texteditor = ({
  editorState,
  setEditorState,
  title,
  handleTitleChange,
  readOnly,
}) => {
  const [snackbarMessage, setSnackbarMessage] = useState({
    content: "",
    severity: "success",
  });
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const MAX_TITLE_LENGTH = 50;

  // Function to handle copying text to clipboard
  const handleCopyToClipboard = () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const text = rawContent.blocks.map((block) => block.text).join("\n");

    navigator.clipboard.writeText(text).then(
      () => {
        setSnackbarMessage({
          content: "Text copied to clipboard!",
          severity: "success",
        });
      },
      () => {
        setSnackbarMessage({
          content: "Failed to copy text to clipboard.",
          severity: "error",
        });
      }
    );
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        border: readOnly ? "1px solid #ddd" : "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: readOnly ? "#f9f9f9" : "#fff",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        boxSizing: "border-box",
      }}
    >
      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarMessage.content !== ""}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage({ content: "", severity: "success" })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarMessage({ content: "", severity: "success" })}
          severity={snackbarMessage.severity}
        >
          {snackbarMessage.content}
        </Alert>
      </Snackbar>

      {/* Title Field */}
      {title && (
        <div style={{ marginBottom: "10px" }}>
          {!isEditingTitle ? (
            <h3
              onClick={() => setIsEditingTitle(true)}
              style={{
                margin: 0,
                fontSize: "1.2rem",
                color: "#333",
                cursor: "pointer",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "100%",
              }}
              title={title}
            >
              {title.length > MAX_TITLE_LENGTH
                ? `${title.slice(0, MAX_TITLE_LENGTH)}...`
                : title}
            </h3>
          ) : (
            <TextField
              value={title}
              onChange={(e) => handleTitleChange(e.target.value)}
              onBlur={() => setIsEditingTitle(false)}
              size="small"
              variant="outlined"
              autoFocus
              fullWidth
            />
          )}
        </div>
      )}

      {/* Toolbar */}
      <div
        style={{
          flexShrink: 0,
          borderBottom: "1px solid #ddd",
          padding: "10px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#f8f9fa",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: "1rem", color: "#333" }}>
          Editor Toolbar
        </span>
        <button
          onClick={handleCopyToClipboard}
          style={{
            padding: "8px 16px",
            border: "none",
            borderRadius: "4px",
            backgroundColor: "#007bff",
            color: "#fff",
            fontWeight: "bold",
            cursor: "pointer",
            transition: "background-color 0.3s",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#0056b3")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#007bff")}
        >
          Copy to Clipboard
        </button>
      </div>

      {/* Editor Content */}
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          backgroundColor: "#fff",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          spellCheck
          readOnly={readOnly}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-content"
          editorStyle={{
            padding: "10px 10px 20px 20px",
            height: "100%",
            backgroundColor: "inherit",
            lineHeight: "1.6",
            fontSize: "1rem",
            color: "#333",
          }}
        />
      </div>
    </div>
  );
};

Texteditor.propTypes = {
  editorState: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  title: PropTypes.string,
  handleTitleChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

Texteditor.defaultProps = {
  title: "",
  handleTitleChange: () => {},
  readOnly: false,
};

export default Texteditor;
