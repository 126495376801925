// Libraries
import React, { useEffect, useRef, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Material UI Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

// Local Files
import AiChatSelection from "./aiChatSelection.js";
import ChatMessage from "./chatMessage.js";
import SuggestedPrompts from './suggestedPrompts';
import AiPromptBar from "./aiPromptBar.js";
import ModelSelector from "./modelSelector.js";
import ServiceSelector from "./serviceSelector.js";
import userApi from "../../server/usersApi";
import completionsApi from "../../server/completionsApi.js";
import { generateConfig } from "./aiChatConfigHelper";
import { copyToClipboard } from "../reusableComponent/helpers.js";
import { userProfileContext } from "../../lib/UserProvider";
import {
  findByValue,
  convertToTemperatureValue,
  findFirstTeamByUseCase
} from "./helpers.js";
import {
  extractMetadata,
  cleanContent
} from "../reusableComponent/helpers.js";
import {
  teamServiceTypeMapping,
  languages,
  lengths,
  temperatures,
  tones,
  teams,
  serviceTypes,
  models,
  publications,
  articleTypes,
  empty,
  booleans,
  getServiceTypes
} from "../../definitions";

// Material UI Icons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ReplayIcon from "@mui/icons-material/Replay";

const CircleIconButton = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  borderRadius: "50%",
  border: `1px solid ${theme.palette.primary.main}`,
  "&:hover": {
    background: "transparent",
  },
}));

const AiChat = () => {
  let navigate = useNavigate();
  const userProfile = useContext(userProfileContext);
  const { id } = useParams();
  const chatBoxRef = useRef(null);
  const latestMessageRef = useRef(null);

  const chatServiceType = getServiceTypes(serviceTypes, {
    specificServiceType: "Chat",
  })[0]?.value || serviceTypes[0].value;

  const [input, setInput] = useState({
    id: id || null,
    useQueue: booleans[0].value,
    options: {
      max_length: lengths[3].value,
      messages: [],
      model: models[0].value,
      temperature: temperatures[5].value,
      user: userProfile?.user_id || null,
      suggestionsEnabled: true
    },
    settings: {
      articleTone: tones[5].value,
      language: languages[0].value,
      serviceType: chatServiceType,
      writingStyle: empty.value,
      team: teams[0].value,
    },
    user_id: userProfile?.user_id || null,
    user_name: userProfile?.name?.split(' ')[0] || null,
    isTemporaryChat: false
  });
  const [showWatermark, setShowWatermark] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [showRegenerate, setShowRegenerate] = useState(false);
  const [aiChatSelectionVisible, setAiChatSelectionVisible] = useState(true);
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState({
    content: "",
    severity: "success",
  });

  // Check for `fullscreenMode` in the URL and update state
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const fullscreenMode = searchParams.get("fullscreenMode");
    setAiChatSelectionVisible(fullscreenMode !== "true");
  }, []);

  // Function to toggle `aiChatSelectionVisible` and update the URL
  const toggleFullscreenMode = () => {
    const updatedFullscreenMode = !aiChatSelectionVisible;

    setAiChatSelectionVisible(updatedFullscreenMode);

    const searchParams = new URLSearchParams(window.location.search);
    if (!updatedFullscreenMode) {
      searchParams.set("fullscreenMode", "true");
    } else {
      searchParams.delete("fullscreenMode");
    }

    // Update the URL without reloading the page
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const fetchSavedChat = async () => {
    try {
      const data = await userApi.fetchUserMessageById(userProfile.user_id, id);
      if (data.length > 0) {
        const config = data[0];
        const messages = config?.options?.messages ? config.options.messages : config.messages;

        const { settings, options } = config;
        const selectedTeam = findFirstTeamByUseCase(settings.serviceType, teamServiceTypeMapping);

        setInput((prevInput) => ({
          ...prevInput,
          id: config.id,
          options: {
            ...prevInput.options,
            max_length: options.max_length,
            messages: messages,
            model: options.model,
            temperature: settings?.articleCreativity !== undefined
              ? convertToTemperatureValue(temperatures, settings.articleCreativity)
              : options?.temperature !== undefined
                ? convertToTemperatureValue(temperatures, options.temperature)
                : prevInput.options.temperature,
            user: userProfile?.user_id || null,
          },
          settings: {
            ...prevInput.settings,
            articleTone: settings.articleTone,
            language: settings.language,
            serviceType: settings.serviceType,
            writingStyle: settings.writingStyle,
            team: selectedTeam,
          },
          user_id: userProfile?.user_id || null,
        }));
      }
    } catch (error) {
      console.error("Error fetching chat data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSavedChat();
    }
  }, [id]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const modelId = searchParams.get("model");

    if (modelId) {
      setInput((prevInput) => ({
        ...prevInput,
        options: {
          ...prevInput.options,
          model: findByValue(models, modelId),
        },
      }));
    }
  }, [location.search]);
  
  const navigateToPushToCue = (combinedInput) => {
    navigate("/push-to-cms", { state: combinedInput });
  };

  const processAndPushArticleData = async (chatContents) => {
    const metadata = extractMetadata(chatContents);
    const cleanedText = cleanContent(chatContents);

    const updatedArticleData = {
      title: cleanContent(metadata.title, true),
      leadText: cleanContent(metadata.leadText, true),
      metaTitle: cleanContent(metadata.metaTitle, true),
      metaDescription: cleanContent(metadata.metaDescription, true),
      newsLettersAndReferrersHeadline: cleanContent(metadata.newsLettersAndReferrersHeadline, true),
      body: cleanedText
    };

    const updatedOptions = {
      model: input?.options?.model ?? null,
      temperature: input?.options?.temperature ?? null,
      max_length: input?.options?.max_length ?? null,
      messages: input?.options?.messages ?? null,
    };

    const updatedSettings = {
      serviceType: input?.settings?.serviceType ?? null,
      language: input?.settings?.language ?? null,
      articleTone: input?.settings?.articleTone ?? null,
    };

    const updatedCmsData = {
      ...input?.settings?.writingStyle && {
        cms: input.settings.writingStyle,
        cmsEntries: [input.settings.writingStyle]
      },
      cmsConfig: {},
      cmsContentType: articleTypes[15].value,
      cmsPublicationStatus: "draft",
    }

    const combinedInput = {
      articleData: updatedArticleData,
      options: updatedOptions,
      settings: updatedSettings,
      cmsData: updatedCmsData
    };

    navigateToPushToCue(combinedInput);
  };

  const handleAction = (actionType, chat) => {
    if (actionType === "copy" && chat.role === "assistant") {
      // Copy the message to the clipboard
      const messageToCopy = chat.content;
      copyToClipboard(messageToCopy)
        .then(() => {
          // Show a success message in the Snackbar
          setSnackbarMessage({
            content: "Message copied to clipboard.",
            severity: "success",
          });
        })
        .catch((error) => {
          console.error("Error copying to clipboard:", error);
          // Show an error message in the Snackbar
          setSnackbarMessage({
            content: "Error copying message.",
            severity: "error",
          });
        });
    } else if (actionType === "sendToCue" && chat.role === "assistant") {
      const chatContents = chat.content;
      processAndPushArticleData(chatContents);
    }
  };

  useEffect(() => {
    if (latestMessageRef.current && chatBoxRef.current) {
      // Get the top offset of the latest message relative to the chat box
      const latestMessageOffset = latestMessageRef.current.offsetTop;
      chatBoxRef.current.scrollTop = latestMessageOffset; // Scroll the chat box to align the latest message at the top
    }

    // Update the showWatermark state based on the content or message history
    if (input.options.messages.length === 0) {
      setShowWatermark(true);
      setShowRegenerate(false);
    } else {
      setShowWatermark(false);

      // Check if the last message is an assistant message to show the Regenerate Response option
      const lastMessage = input.options.messages[input.options.messages.length - 1];
      if (lastMessage?.role === "assistant") {
        setShowRegenerate(true);
      } else {
        setShowRegenerate(false);
      }
    }
  }, [input.options.messages]);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleRegenerateResponse = () => {
    if (input.options.messages.length > 0) {
      let updatedMessages = input.options.messages.slice(0, -1);

      setInput((prevInput) => ({
        ...prevInput,
        options: {
          ...prevInput.options,
          messages: updatedMessages,
        },
      }));

      const hasAssistantMessages = updatedMessages.some(chat => chat.role === "assistant");
      setShowRegenerate(hasAssistantMessages);

      const regeneratedConfig = {
        ...input,
        options: {
          ...input.options,
          max_length: input?.options?.max_length ? input.options.max_length : "5000",
          messages: updatedMessages,
          model: input?.options?.model ? input.options.model : "gpt-4",
          temperature: input?.options?.temperature ? input.options.temperature : 0.5,
          user: input?.options?.user ? input.options.user : (userProfile?.user_id || null),
        }
      };

      if (regeneratedConfig?.messages) {
        delete regeneratedConfig.messages;
      }

      handleSendMessage(regeneratedConfig);
    }
  };

  const handleSendMessage = async (config) => {
    const { settings, options } = config;
    const { messages } = options;
    setLoadingMessage({
      role: "assistant",
      content: "Mantis Scribe is Typing...",
    });

    if (!messages.length) {
      throw new Error("Please provide a message.");
    }

    try {
      const enqueueMessage = async (config) => {
        const response = await completionsApi.enqueueMessageToCompletionsAPI(config);
        if (response?.status === 202 && response?.id) {
          const intervalId = setInterval(async () => {
            const task = await completionsApi.checkTaskStatusInCompletionsQueue(response.id);
            if (task) {
              const taskStatus = task.message;
              if (taskStatus === 'Completed' || taskStatus === 'Failed') {
                clearInterval(intervalId);
                const fetchedChat = JSON.parse(task.output);
                handleFetchedChat(fetchedChat);
              }
            }
          }, 8000);

          setTimeout(() => {
            clearInterval(intervalId);
          }, 240000);
        } else {
          throw new Error(`${response?.response?.data?.message ? response.response.data.message : response.message}`);
        }
      }

      // Directly handle the API response or enqueue based on the useQueue flag
      const shouldEnqueue = input.useQueue || false; // Ensure boolean value

      if (!settings.serviceType.includes("Article") || !shouldEnqueue) {
        const response = await completionsApi.sendMessageToCompletionsAPI(config);
        if (response.status === 200) {
          handleFetchedChat(response);
        } else if (response?.response?.status === 504) {
          await enqueueMessage(config);
        } else {
          throw new Error(`${response?.response?.data?.message ? response.response.data.message : response.message}`);
        }
      } else {
        await enqueueMessage(config);
      }

    } catch (error) {
      setLoadingMessage(null);

      const errorMessage = `${error?.toString() ?? "Error: An error occurred. Please try again."}`;
      setInput((prevInput) => ({
        ...prevInput,
        options: {
          ...prevInput.options,
          messages: [
            ...(prevInput.options.messages || []),
            { role: "assistant", content: errorMessage }
          ]
        }
      }));
    }
  };

  const handleFetchedChat = (fetchedChat) => {
    if (fetchedChat !== null) {
      const { message = "", suggestions = [] } = fetchedChat;

      const messageResponse = typeof message === "string" ? message : "";
      setLoadingMessage(null);

      setInput((prevInput) => ({
        ...prevInput,
        id: fetchedChat?.id || null,
        options: {
          ...prevInput.options,
          messages: [
            ...(prevInput.options.messages || []),
            { role: "assistant", content: messageResponse }
          ],
        },
      }));

      // Update suggestions only if enabled
      if (input.options.suggestionsEnabled) {
        setSuggestedPrompts(suggestions);
      }
    } else {
      setLoadingMessage(null);

      const errorMessage = `An error occurred. Please try again.`;
      setInput((prevInput) => ({
        ...prevInput,
        options: {
          ...prevInput.options,
          messages: [
            ...(prevInput.options.messages || []),
            { role: "assistant", content: errorMessage }
          ],
        },
      }));
    }
  };

  const handleStartNewChat = () => {
    // Filter out messages with the role 'system' and retain them
    const systemMessages = input.options.messages.filter((message) => message.role === "system");

    // Set input state to start a new chat, retaining only the system messages
    setInput((prevInput) => ({
      ...prevInput,
      id: null,
      options: {
        ...prevInput.options,
        messages: [...systemMessages], // Only retain the system messages
      },
    }));
  };

  const handleModelChange = (newModel) => {
    setInput((prevInput) => ({
      ...prevInput,
      options: {
        ...prevInput.options,
        model: newModel,
      },
    }));
  };

  const handleServiceChange = (newServiceType) => {
    setInput((prevInput) => ({
      ...prevInput,
      settings: {
        ...prevInput.settings,
        serviceType: newServiceType,
      },
    }));
  };

  return (
    <div>
      <Container maxWidth="xl" style={{ height: "100%" }}>
        {/* Snackbar */}
        <Snackbar
          open={snackbarMessage.content !== ""}
          autoHideDuration={3000}
          onClose={() =>
            setSnackbarMessage({ content: "", severity: "success" })
          }
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <div>
            <Alert
              onClose={() =>
                setSnackbarMessage({ content: "", severity: "success" })
              }
              severity={snackbarMessage.severity}
            >
              {snackbarMessage.content}
            </Alert>
          </div>
        </Snackbar>
        <div className="ai-chat" style={{ height: "100%" }}>
          {/* Minimise Chat Selection */}
          <CircleIconButton
            color="primary"
            onClick={toggleFullscreenMode}
          >
            {aiChatSelectionVisible ? <RemoveIcon /> : <AddIcon />}
          </CircleIconButton>
          <Grid container spacing={2} style={{ height: "100%" }}>
            {/* Chat Selection */}
            {aiChatSelectionVisible && (
              <Grid item sm={5} style={{ height: "100%" }}>
                <div data-testid="settings-component" style={{ marginBottom: "16px" }}>
                  <AiChatSelection
                    input={input}
                    setInput={setInput}
                  />
                </div>
                <AiPromptBar
                  onSend={({ message, tool_choice }) => {
                    if (!message || typeof message !== "string") {
                      console.error("Invalid message passed to onSend.");
                      return;
                    }

                    // Append the message to the existing messages in the state
                    const updatedMessages = [
                      ...(input.options.messages || []),
                      { role: "user", content: message },
                    ];

                    setInput((prevInput) => ({
                      ...prevInput,
                      options: {
                        ...prevInput.options,
                        messages: updatedMessages,
                      },
                    }));

                    // Create config overrides including the updated messages
                    const createConfigOverrides = () => ({
                      options: {
                        user: userProfile?.user_id ?? null,
                        messages: updatedMessages, // Include updated messages here
                        tool_choice,
                      },
                      user_id: userProfile?.user_id ?? null,
                    });

                    const configOverrides = input
                      ? {
                        ...input,
                        message,
                        options: {
                          ...input.options,
                          user: userProfile?.user_id ?? null,
                          messages: updatedMessages,
                          tool_choice,
                        },
                        user_id: userProfile?.user_id ?? null,
                      }
                      : createConfigOverrides();

                    // Generate the config and send the message
                    const config = generateConfig(configOverrides);
                    handleSendMessage(config);
                  }}
                  language={input.settings.language}
                  variant="aiSettings"
                />
              </Grid>
            )}
            <Grid item sm={aiChatSelectionVisible ? 7 : 12}>
              <div>
                <div
                  style={{
                    backgroundColor: "#343542",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                >
                  {/* Chat Box */}
                  <Box
                    id="chat-box"
                    ref={chatBoxRef}
                    className="chat-box"
                    style={{
                      minHeight: "300px",
                      overflow: "auto",
                      height: `calc(100vh - 259px)`,
                      position: "relative", // Add position relative for watermark positioning
                    }}
                  >
                    {showWatermark && !aiChatSelectionVisible && (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "16px" }}
                      >
                        {/* Left: Model Selector */}
                        <ModelSelector
                          currentModel={input.options.model}
                          models={models}
                          onModelChange={handleModelChange}
                        />
                        {/* Right: Service Selector */}
                        <ServiceSelector
                          currentService={input.settings.serviceType}
                          services={serviceTypes}
                          onServiceChange={handleServiceChange}
                        />
                      </Box>
                    )}
                    {/* Mantis Watermark */}
                    {showWatermark && ( // Render the watermark only if showWatermark is true
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          pointerEvents: "none", // Make the watermark non-interactable
                          zIndex: 1, // Adjust the z-index as needed
                          color: "rgb(84 85 103)",
                          fontSize: "24px",
                          fontWeight: "bold",
                          fontFamily: "Arial, sans-serif",
                          textAlign: "center",
                          textTransform: "uppercase",
                          letterSpacing: "4px",
                        }}
                      >
                        Mantis Scribe Chat
                      </div>
                    )}
                    {showWatermark && ( // Render suggested prompts if watermark is shown
                      <Box
                        style={{
                          position: "absolute",
                          top: "60%",
                          left: "50%",
                          transform: "translate(-50%, 0)",
                          zIndex: 1,
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <SuggestedPrompts
                          title="Try one of these prompts"
                          prompts={serviceTypes.find((type) => type.value === input.settings.serviceType)?.prompts.slice(0, 4)}
                          onSelectPrompt={(prompt) => {
                            const updatedMessages = [
                              ...(input.options.messages || []),
                              { role: "user", content: prompt },
                            ];

                            setInput((prevInput) => ({
                              ...prevInput,
                              options: {
                                ...prevInput.options,
                                messages: updatedMessages,
                              },
                            }));

                            handleSendMessage({
                              ...input,
                              options: {
                                ...input.options,
                                messages: updatedMessages,
                              },
                            });
                          }}
                        />
                      </Box>
                    )}
                    {/* Render chat messages using the ChatMessage component */}
                    {[...input.options.messages, loadingMessage].map((chat, index) =>
                      chat ? (
                        <div
                          data-testid="ChatMessage-component"
                          key={index}
                          ref={index === input.options.messages.length - 1 ? latestMessageRef : null} // Attach ref to the latest message
                        >
                          <ChatMessage
                            chat={chat}
                            chatConfig={input}
                            index={index}
                            handleAction={handleAction}
                          />
                          {/* Display dynamic prompts if they exist */}
                          {chat.role === "assistant" &&
                            index === input.options.messages.length - 1 &&
                            input.options.suggestionsEnabled &&
                            suggestedPrompts.length > 0 && (
                              <SuggestedPrompts
                                prompts={suggestedPrompts}
                                onSelectPrompt={(prompt) => {
                                  const updatedMessages = [
                                    ...input.options.messages,
                                    { role: "user", content: prompt },
                                  ];

                                  setInput((prevInput) => ({
                                    ...prevInput,
                                    options: {
                                      ...prevInput.options,
                                      messages: updatedMessages,
                                    },
                                  }));

                                  handleSendMessage({
                                    ...input,
                                    options: {
                                      ...input.options,
                                      messages: updatedMessages,
                                    },
                                  });
                                }}
                              />
                            )}
                        </div>
                      ) : null
                    )}
                    {/* Conditionally render AiPromptBar */}
                  </Box>
                  {!aiChatSelectionVisible && (
                    <AiPromptBar
                      onSend={({ message, tool_choice }) => {
                        if (!message || typeof message !== "string") {
                          console.error("Invalid message passed to onSend.");
                          return;
                        }

                        // Append the message to the existing messages in the state
                        const updatedMessages = [
                          ...(input.options.messages || []),
                          { role: "user", content: message },
                        ];

                        setInput((prevInput) => ({
                          ...prevInput,
                          options: {
                            ...prevInput.options,
                            messages: updatedMessages,
                          },
                        }));

                        // Create config overrides including the updated messages
                        const createConfigOverrides = () => ({
                          options: {
                            user: userProfile?.user_id ?? null,
                            messages: updatedMessages, // Include updated messages here
                            tool_choice,
                          },
                          user_id: userProfile?.user_id ?? null,
                        });

                        const configOverrides = input
                          ? {
                            ...input,
                            message,
                            options: {
                              ...input.options,
                              user: userProfile?.user_id ?? null,
                              messages: updatedMessages,
                              tool_choice,
                            },
                            user_id: userProfile?.user_id ?? null,
                          }
                          : createConfigOverrides();

                        // Generate the config and send the message
                        const config = generateConfig(configOverrides);
                        handleSendMessage(config);
                      }}
                      language={input.settings.language}
                      mode='dark'
                      variant="aiChat"
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* New Chat button */}
                  <Button
                    endIcon={<AddIcon />}
                    variant="contained"
                    style={{ backgroundColor: "#24ADE4", marginRight: "8px" }}
                    onClick={handleStartNewChat}
                  >
                    New Chat
                  </Button>

                  {/* Regenerate Chat button */}
                  {showRegenerate && (
                    <Button
                      endIcon={<ReplayIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={handleRegenerateResponse}
                    >
                      Regenerate
                    </Button>
                  )}
                </div>
                <Box
                  mt={1.2}
                  bgcolor="#f8f8f8"
                  borderRadius={4}
                  fontSize={12}
                  textAlign="center"
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    title="Mantis Scribe provides information based on available data. While we strive to maintain accuracy, please note that Mantis Scribe's responses may occasionally contain inaccuracies regarding people, places, or facts. It is recommended to independently verify important details before relying on the information provided. Mantis Scribe is intended for assistance purposes and should not replace professional advice or thorough research."
                  >
                    <em>Mantis Scribe can make mistakes. Verify important details.</em>
                  </Typography>
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default AiChat;
